<script>
import ColorInput from './ColorInput.vue';
import Button from './Button.vue';
import SliderParam from './SliderParam.vue';
import InputField from './InputField.vue';
import RadioToggle from './RadioToggle.vue';
import ParamLabel from './ParamLabel.vue';
import Icon from './Icon.vue';
import DropdownMenu from './DropdownMenu.vue';
import ClassicToggle from './ClassicToggle.vue';
import MaskControls from './MaskControls.vue';
import StateEffects from './StateEffects.vue';
import DisplacementControls from './DisplacementControls.vue';
import AlignmentControls from './AlignmentControls.vue';
import SizeInput from './SizeInput.vue';
import PanelNav from './PanelNav.vue';
import { BLEND_MODES } from '../scripts/Constants.js';
import { StudioStore } from '../stores/StudioStore.js';
import { Vec2 } from 'curtainsjs';

export default {
  components: {
    ColorInput,
    InputField,
    SliderParam,
    Button,
    Icon,
    DisplacementControls,
    AlignmentControls,
    SizeInput,
    ParamLabel,
    MaskControls,
    StateEffects,
    ClassicToggle,
    DropdownMenu,
    RadioToggle,
    PanelNav,
  },
  props: ['randomizer'],
  data() {
    return {
      state: StudioStore.state,
      blendModes: BLEND_MODES,
      anchorOptions: {
        center: 'Center',
        topLeft: 'Top Left',
        topCenter: 'Top Center',
        topRight: 'Top Right',
        rightCenter: 'Right Center',
        bottomRight: 'Bottom Right',
        bottomCenter: 'Bottom Center',
        bottomLeft: 'Bottom Left',
        leftCenter: 'Left Center',
      },
      panelView: 'design',
      panelOptions: {
        design: 'Design',
        interactivity: 'Events'
      },
    };
  },
  created() {
    if(this.stateEffect) {
      this.panelView = 'interactivity';
    }
  },
  computed: {
    image() {
      return StudioStore.getSelectedItem() || StudioStore.state.image;
    },
    width() {
      return Math.round(this.image.width * this.image.size);
    },
    height() {
      return Math.round(this.image.height * this.image.size);
    },
    stateEffect() {
      return StudioStore.state.openStateEffect;
    }
  },
  watch: {
    stateEffect() {
      if(this.stateEffect) {
        this.panelView = 'interactivity';
      }
    }
  },
  methods: {
    updateValue(value, name) {
      this.effect[name] = value;
      this.$emit('update-value', this.effect.local.id, name, value);
    },
    handleBlendModeChange() {
      this.$emit('change');
    },
    handleBlendModePreview() {
      StudioStore.renderFrame();
    },
    handleMaskChange() {
      StudioStore.refreshPlanes(() => {
        StudioStore.renderFrame();
      }, this.image);
      this.$emit('change');
    },

  },
};
</script>

<template>
  <div class="effect-properties control-section-wrapper" ref="effectWindow">
    <div class="parameter parameter__block">
      <label class="parameter-label">Image</label>
    </div>

    <PanelNav
      v-model="panelView"
      :options="panelOptions"
    />

    <template v-if="panelView === 'interactivity'">
      <StateEffects :item="image" />
    </template>
    <template v-else-if="panelView === 'design'">
      <AlignmentControls :item="image" @change="$emit('change')" />
      <div class="image-effect-preview mt-1" @click="$emit('replace-image', image.local.id)">
        <Button class="replace-image secondary-inverse replace-image-button">Replace image</Button>
        <img :src="image.thumb" />
      </div>
      <MaskControls
        :item="image"
        @change="$emit('change')"
        @edit-fill="$emit('edit-fill', { item: image, prop: 'maskBackground' })"
      />
      <ClassicToggle
        label="Fit to artboard"
        v-model="image.fitToCanvas"
        tooltip="Fits the image to best fill the entire artboard for any given aspect ratio."
        @update:modelValue="$emit('input')"
      ></ClassicToggle>
      <template v-if="!image.fitToCanvas">
        <div class="parameter parameter__block">
          <ParamLabel prop="pos" label="Position" @reset-breakpoint-prop="$emit('update')">Position</ParamLabel>
          <div class="coords-input-group">
            <InputField label="X" v-model="image.left" output="percent" :slider="true" @change="$emit('input')" />
            <InputField label="Y" v-model="image.top" output="percent" :slider="true" @change="$emit('input')" />
          </div>
        </div>
        <div class="parameter parameter__block">
          <ParamLabel prop="anchorPoint" label="Anchor">Anchor</ParamLabel>
          <DropdownMenu
            :modelValue="image.anchorPoint"
            :options="anchorOptions"
            prop="anchorPoint"
            @update="$emit('update')"
            @change="
              image.setAnchorPoint($event);
              $emit('input');
            "
          ></DropdownMenu>
        </div>

        <SizeInput
          v-if="!image.fitToCanvas"
          label="Width"
          v-model="image.width"
          :mode="image.widthMode"
          :alternateMode="image.heightMode"
          :disableAuto="image.widthMode === 'auto'"
          dimension="width"
          prop="width"
          @input="$emit('input')"
          @update="$emit('update')"
          @change-mode="image.setDimensionMode('width', $event)"
        />
        <SizeInput
          v-if="!image.fitToCanvas"
          label="Height"
          v-model="image.height"
          :mode="image.heightMode"
          :alternateMode="image.widthMode"
          :disableAuto="image.heightMode === 'auto'"
          dimension="height"
          prop="height"
          @input="$emit('input')"
          @update="$emit('update')"
          @change-mode="image.setDimensionMode('height', $event)"
        />

        <SliderParam
          label="Rotation"
          v-model="image.rotation"
          :min="0"
          :max="1"
          :step="0.0027"
          prop="rotation"
          output="degrees"
          @update="$emit('input')"
        ></SliderParam>
      </template>

      <SliderParam
        label="Opacity"
        v-model.number="image.opacity"
        :min="0"
        :max="1"
        :step="0.01"
        output="percent"
        prop="opacity"
        @update="$emit('input')"
      ></SliderParam>
      <div class="parameter parameter__block">
        <label class="icon-label slider-label">Blend mode</label>
        <DropdownMenu
          v-model="image.blendMode"
          :options="blendModes"
          :rolloverPreview="true"
          @update:modelValue="$emit('input')"
          @input="handleBlendModePreview"
          @update="handleBlendModePreview"
          @change="handleBlendModeChange"
        ></DropdownMenu>
      </div>
      <DisplacementControls :item="image" @input="$emit('input')" @change="$emit('change')"></DisplacementControls>
      <SliderParam
        label="Mouse tracking"
        header="Interactivity"
        v-model.number="image.trackMouse"
        :min="0"
        :max="1"
        :step="0.01"
        output="percent"
        prop="trackMouse"
        @update="$emit('input')"
      ></SliderParam>
      <SliderParam
        label="Momentum"
        v-model.number="image.mouseMomentum"
        tooltip="The amount of drag or delay of the track mouse effect"
        :min="0"
        :max="1"
        :step="0.01"
        output="percent"
        prop="mouseMomentum"
        @update="$emit('input')"
      ></SliderParam>
      <SliderParam
        label="3D axis tilt"
        v-model.number="image.axisTilt"
        :min="0"
        :max="1"
        :step="0.01"
        output="percent"
        prop="axisTilt"
        @update="$emit('input')"
      ></SliderParam>
    </template>
  </div>
</template>
