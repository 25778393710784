<script>
import DesignItem from '../components/DesignItem.vue';
import Folder from '../components/Folder.vue';
import AdminFlyout from '../components/AdminFlyout.vue';
import TemplateItem from '../components/TemplateItem.vue';
import DesignLoadingItem from '../components/DesignLoadingItem.vue';
import Navigation from '../components/Navigation.vue';
import ProModal from '../components/ProModal.vue';
import Button from '../components/Button.vue';
import Icon from '../components/Icon.vue';
import { UserStore } from "../stores/UserStore.js";
import { DesignsStore } from "../stores/DesignsStore.js";
import router from '../router';

export default {
  components: {
      DesignItem,
      TemplateItem,
      Folder,
      AdminFlyout,
      ProModal,
      Button,
      Navigation,
      Icon,
      DesignLoadingItem
  },
  data() {
      return {
          UserStore,
          DesignsStore,
          pendingDelete: null,
          renaming: false,
          proModalOpen: false,
          creatingDesign: false,
          creatingFolder: false,
          contextMenuOpen: false,
      }
  },
  mounted() {
    if(UserStore.id) {
      if(!DesignsStore.state.fetchedFolders) {
        DesignsStore.getFolders().then(() => {
          DesignsStore.getFolderDesigns()
        });
      }
      if(!DesignsStore.state.fetchedDesigns) {
        DesignsStore.getDesigns(24);
      }
    }
    if(!DesignsStore.state.templates.length) {
      DesignsStore.getTemplates();
    }
  },
  watch: {
    "UserStore.id"() {
      if(UserStore.id) {
        if(!DesignsStore.state.fetchedFolders) {
          DesignsStore.getFolders().then(() => {
            DesignsStore.getFolderDesigns()
          });
        }
        if(!DesignsStore.state.fetchedDesigns) {
          DesignsStore.getDesigns(24);
        }
      }
      if(!DesignsStore.state.templates.length) {
        DesignsStore.getTemplates();
      }
    },
  },
  computed: {
    numPlaceholder() {
      return UserStore.designCount || 0;
    },
    hasProAcess() { return UserStore.hasProAcess}
  },  
  methods: {
    createDesign() {
      this.creatingDesign = true;
      DesignsStore.createDesign((id, vid) => {
        router.push('edit/' + id);
      }, {});
    },
    createFolder() {
      if(!this.hasProAcess) {
        this.proModalOpen = true;
        return;
      }
      this.creatingFolder = true;
      DesignsStore.createFolder(() => {
        this.creatingFolder = false;
      });
    },
    loadMoreDesigns() {
      
      DesignsStore.getDesigns();
    }
  }
}
</script>

<template>
    <div class="admin-container">
      <div v-if="!UserStore.id || DesignsStore.state.loadingDesigns" class="loading-bar"></div>
      <Navigation @open-promodal="proModalOpen = true" />
      <div class="template-listing-container">
        <h1 class="h3 mb-2 ml-5">Starter templates</h1>
        <div class="starter-templates flex align-center">
          <template v-if="!UserStore.id || DesignsStore.state.loadingTemplates">
              <DesignLoadingItem
                  v-for="index in 13"
                  :template="true"
                  :key="'design_item_' + index"
              ></DesignLoadingItem>
          </template>
          <div class="starter-template-scroll-container" v-else>
            <TemplateItem 
              v-for="design in DesignsStore.state.templates"
              :design="design"
              :key="design.id"
            ></TemplateItem> 
          </div>
        </div>
      </div>
      <div class="design-listing-container">
        <div class="mb-2 mt-3 flex align-center">
          <h1 class="h3 my-0">Your projects</h1>
          <div class="flex align-center ml-auto">
            <Button class="small secondary mr-2" @click="createFolder" :loading="creatingFolder">
              <Icon v-if="!creatingFolder" icon="plus" class="mr-1 font-secondary-color" /> New folder
              <Icon v-if="!hasProAcess" class="ml-1" :size="10" icon="legend" style="color: var(--gold)" />
            </Button>
            <Button :loading="creatingDesign" @click="createDesign" class="primary small">
              <Icon v-if="!creatingDesign" icon="plus" class="mr-1" /> New project
            </Button>
          </div>
        </div>
        <div class="design-container">
          <Folder
            v-for="folder in DesignsStore.state.folders"
            :folder="folder"
            :key="folder.id"
          >
          </Folder>
          <DesignItem
            v-for="design in DesignsStore.state.displayedDesigns"
            :design="design"
            :key="design.id"
          ></DesignItem>
        <template v-if="DesignsStore.state.loadingDesigns">
            <DesignLoadingItem
                v-for="index in 6"
                :key="'design_item_' + index"
            ></DesignLoadingItem>
        </template>
        </div>
        <div v-if="DesignsStore.state.designs.length >= 23 && DesignsStore.state.hasMoreDesigns" class="pagination">
          <a href="javascript:void(0)" @click="loadMoreDesigns" class="page-number" :class="{'page-number__loading': DesignsStore.state.loadingDesigns}">
            <template v-if="DesignsStore.state.loadingDesigns">Loading more...</template>
            <template v-else>Load more</template>
          </a>
        </div>  
      </div>
      <!-- <div class="design-listing-container">
        <h1 class="h3 mb-2">Tutorials</h1>
        <div class="tutorial-container">
          <a class="tutorial" href="https://youtu.be/qMpEm5fy_BY" target="_blank">

            <p>Making "Appear Events" from scratch</p>
          </a>
        </div>
      </div> -->
      <ProModal v-if="proModalOpen" @close="proModalOpen = false" />
    </div>
</template>


<style lang="scss" scoped>

.admin-container {
  width: 100%;
  margin: 0 auto;
}

.small-warning-banner {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  color: var(--primary-color-light);
  background-color: var(--primary-color-50a);
}

.h1 {
  font-family: 'Bebas Neue';
}

.logo-container {
  margin-top: 0.5rem;
  width: 3rem;

  svg {
    object-fit: contain;
  }
}

.navigation {
  width: 100%;
  justify-content: space-between;
  padding: 1.5rem 3rem;
}

.design-listing-container {
  width: 100%;
  padding: 3rem;
  border-top: 1px solid var(--accent-color);
}

.template-listing-container {
  width: 100%;
  padding: 3rem 0 3rem 0rem;
  border-top: 1px solid var(--accent-color);
}

.design-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  break-inside: avoid;
  // box-shadow: inset 0 0 0 0.1rem var(--accent-color);
}

.pagination {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.page-number {
  padding: 1rem 1.5rem;
  border: 1px solid var(--accent-color);
  color: var(--font-secondary-color);
  text-decoration: none;

  &:hover {
    background-color: var(--accent-color);
    color: var(--font-color);
  }

  &.page-number__loading {
    background-color: var(--accent-color);
    color: var(--font-color);
    pointer-events: none;
  }
}

.starter-templates {
  margin-top: var(--unit1);
  margin-bottom: var(--unit1);
  max-width: 100%;
  overflow-x: scroll;
}

.starter-template-scroll-container {
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
}

.new-item-content {
  //background-color: var(--accent-color);
  padding: 2rem 1rem 1.5rem 1rem;
  border-radius: 0.5rem;
}

</style>