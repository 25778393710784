<script>
import { StudioStore } from '../stores/StudioStore'
import { StateEffectAppear, StateEffectScroll, StateEffectHover } from '../scripts/StateEffect'
import StateEffectListingItem from "./StateEffectListingItem.vue";
import ActionMenu from "./ActionMenu.vue";
import { AddStateEffectCommand, RemoveStateEffectCommand } from '../scripts/Commands.js';
import Button from "./Button.vue";
import Icon from "./Icon.vue";

export default {
    components: {
      StateEffectListingItem,
      Button,
      ActionMenu,
      Icon
    },
    props: ['item'],
    data() {
      return {
        duration: 250,
        options: [
          {
            label: 'Appear',
            value: 'appear'
          },
          {
            label: 'Scroll',
            value: 'scroll'
          },
          {
            label: 'Hover',
            value: 'hover'
          },
        ]
      }
    },
    methods: {
      createNewStateEffect(type, dup) {
        const params = this.item.getParams().params;
        const firstValidEntry = Object.entries(params.uniforms).find(([key, value]) => !['resolution', 'mousePos', 'previousMousePos', 'time'].includes(key) && value.type !== "1i");
        StudioStore.state.openStateEffect = null;

        if (firstValidEntry) {
          const [key, value] = firstValidEntry;
          let stateEffect;
          if(type === 'appear') {
            stateEffect = new StateEffectAppear(dup || {
              prop: key,
              value: value.value,
              endValue: value.value,
              transition: { ease: 'easeInOutQuart', duration: 1000, delay: 0 }
            }, this.item.isElement ? this.item.local.id : null);
          } else if (type === 'hover') {
            stateEffect = new StateEffectHover(dup || {
              prop: key,
              value: value.value,
              transition: { ease: 'easeInOutQuart', duration: 1000, delay: 0 }
            }, this.item.isElement ? this.item.local.id : null);
          } else if (type === 'scroll') {
            stateEffect = new StateEffectScroll(dup || {
              prop: key,
              value: value.value,
              offset: 0.5,
              range: 0.5,
              momentum: 0.25
            }, this.item.isElement ? this.item.local.id : null);
          }
          StudioStore.performAction(new AddStateEffectCommand(this.item, stateEffect));
          StudioStore.state.openStateEffect = stateEffect;
        }
      },
      handleSelectStateEffect(stateEffect, e) {

        if(this.item.states.appear.find(n => n.id === stateEffect.id)) {
          StudioStore.state.openStateEffect = stateEffect;
        } else if(this.item.states.scroll.find(n => n.id === stateEffect.id)) {
          StudioStore.state.openStateEffect = stateEffect;
        } else if(this.item.states.hover.find(n => n.id === stateEffect.id)) {
          StudioStore.state.openStateEffect = stateEffect;
        }
        if(StudioStore.state.openStateEffect) {
          stateEffect.offsetY = e.pageY;
          if(this.item.isBackground) {
            StudioStore.setSelectedItem(this.item);
          }
        }
      },
      handleDeleteStateEffect(id) {
        StudioStore.performAction(new RemoveStateEffectCommand(this.item, id));
        StudioStore.state.openStateEffect = null;
      },
      handleDuplicateStateEffect(stateEffect) {
        let dup = stateEffect.package();
        delete dup.id;
        if (stateEffect instanceof StateEffectAppear) {
          this.createNewStateEffect('appear', dup);
        } else if (stateEffect instanceof StateEffectScroll) {
          this.createNewStateEffect('scroll', dup);
        } else if (stateEffect instanceof StateEffectHover) {
          this.createNewStateEffect('hover', dup);
        }
      },
    }
  };
</script>

<template>
  <div class="state-effect-section">
    <!-- Appear Section -->
    <div class="parameter parameter__block">
      <label class="parameter-label flex align-center">Appear </label>
      <a href="#" @click="createNewStateEffect('appear')" class="icon-button font-secondary-color">
        <Icon icon="plus" size="18" />
      </a>
    </div>
    <div class="mt-2 state-effect-container">
      <StateEffectListingItem
        v-for="(stateEffect, index) in item.states.appear"
        :key="'appear' + index"
        :stateEffect="stateEffect"
        :item="item"
        @delete-state-effect="handleDeleteStateEffect"
        @duplicate-state-effect="handleDuplicateStateEffect"
        @handle-click="handleSelectStateEffect"
      />
    </div>

    <!-- Scroll Section -->
    <div class="parameter parameter__block">
      <label class="parameter-label">Scroll</label>
      <a href="#" @click="createNewStateEffect('scroll')" class="icon-button font-secondary-color">
        <Icon icon="plus" size="18" />
      </a>
    </div>
    <div class="mt-2 state-effect-container">
      <StateEffectListingItem
        v-for="(stateEffect, index) in item.states.scroll"
        :key="'scroll' + index"
        :stateEffect="stateEffect"
        :item="item"
        @delete-state-effect="handleDeleteStateEffect"
        @duplicate-state-effect="handleDuplicateStateEffect"
        @handle-click="handleSelectStateEffect"
      />
    </div>

    <!-- Hover Section -->
    <div class="parameter parameter__block">
      <label class="parameter-label">Hover</label>
      <a href="#" @click="createNewStateEffect('hover')" class="icon-button font-secondary-color">
        <Icon icon="plus" size="18" />
      </a>
    </div>
    <div class="mt-2 state-effect-container">
      <StateEffectListingItem
        v-for="(stateEffect, index) in item.states.hover"
        :key="'hover' + index"
        :stateEffect="stateEffect"
        :item="item"
        @delete-state-effect="handleDeleteStateEffect"
        @duplicate-state-effect="handleDuplicateStateEffect"
        @handle-click="handleSelectStateEffect"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

.state-effect-container {
  border-radius: 0.4rem;
  margin-bottom: 1.5rem;

  &:empty {
    border: none;
    margin-bottom: 0;
  }
}

</style>