import { Vec2, Vec3 } from 'curtainsjs';
import { vertexShaderNoMatrix } from '../ShaderHelpers.js';

const fragmentShader = `#version 300 es
precision highp float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uVelocity;
uniform sampler2D uPressure;
uniform sampler2D uDivergence;
uniform sampler2D uPingPongTexture;
uniform vec2 uMousePos;
uniform vec2 uPreviousMousePos;
uniform vec2 texelSize;
uniform vec2 uResolution;
uniform float dt;
uniform float dissipation;
uniform float uStrength;
uniform float uScale;
uniform float uTime;
uniform vec3 color;
uniform int pass;
uniform bool uDebug;

out vec4 fragColor;

vec4 advect() {
    vec2 aspect = vec2(uResolution.x/uResolution.y, 1);
    vec2 vUv = vTextureCoord;
    vec2 mPos = mix(uMousePos, (uMousePos - 0.5) * 0.5 + 0.5, uScale);
    vec2 pmPos = mix(uPreviousMousePos, (uPreviousMousePos - 0.5) * 0.5 + 0.5, uScale);

    // Calculate mouse movement
    vec2 mouseVel = (mPos - pmPos) * aspect;
    float mouseSpeed = length(mouseVel);
    
    // Get current fluid state
    vec4 result = texture(uTexture, vUv);
    
    // Add mouse force
    float radius = 0.1;
    float dist = distance(vUv * aspect, mPos * aspect);
    
    if (dist < radius && mouseSpeed > 0.0001) {
        float influence = smoothstep(radius, 0.0, dist);
        result.xy += mouseVel * influence * uStrength;
    }
    
    // Advect
    vec2 pos = vUv - dt * result.xy * texelSize;
    result = texture(uTexture, pos);
    
    // Apply dissipation
    float decay = 1.0 + dissipation * dt;
    return result / decay;
}

// Divergence pass
vec4 computeDivergence() {
    vec2 L = texture(uVelocity, vTextureCoord - vec2(texelSize.x, 0.0)).xy;
    vec2 R = texture(uVelocity, vTextureCoord + vec2(texelSize.x, 0.0)).xy;
    vec2 T = texture(uVelocity, vTextureCoord + vec2(0.0, texelSize.y)).xy;
    vec2 B = texture(uVelocity, vTextureCoord - vec2(0.0, texelSize.y)).xy;
    
    float div = 0.5 * ((R.x - L.x) + (T.y - B.y));
    return vec4(div, 0.0, 0.0, 1.0);
}

// Pressure solve pass
vec4 solvePressure() {
    float L = texture(uPressure, vTextureCoord - vec2(texelSize.x, 0.0)).x;
    float R = texture(uPressure, vTextureCoord + vec2(texelSize.x, 0.0)).x;
    float T = texture(uPressure, vTextureCoord + vec2(0.0, texelSize.y)).x;
    float B = texture(uPressure, vTextureCoord - vec2(0.0, texelSize.y)).x;
    float C = texture(uPressure, vTextureCoord).x;
    
    float divergence = texture(uDivergence, vTextureCoord).x;
    float pressure = (L + R + T + B - divergence) * 0.25;
    return vec4(pressure, 0.0, 0.0, 1.0);
}

// Gradient subtraction pass
vec4 subtractGradient() {
    float L = texture(uPressure, vTextureCoord - vec2(texelSize.x, 0.0)).x;
    float R = texture(uPressure, vTextureCoord + vec2(texelSize.x, 0.0)).x;
    float T = texture(uPressure, vTextureCoord + vec2(0.0, texelSize.y)).x;
    float B = texture(uPressure, vTextureCoord - vec2(0.0, texelSize.y)).x;
    
    vec2 velocity = texture(uVelocity, vTextureCoord).xy;
    velocity -= vec2(R - L, T - B) * 0.5;
    return vec4(velocity, 0.0, 1.0);
}

void main() {
    vec4 result;
    
    switch(pass) {
        case 0: result = advect(); break;
        case 1: result = computeDivergence(); break;
        case 2: result = solvePressure(); break;
        case 3: result = subtractGradient(); break;
        default: result = vec4(0.0);
    }

    // Debug visualization
    if (uDebug) {
        switch(pass) {
            case 0:
                // Visualize velocity as RGB
                fragColor = vec4(result.xy * 0.5 + 0.5, 0.0, 1.0);
                break;
            case 1:
                // Visualize divergence as grayscale
                float div = result.x * 0.5 + 0.5;
                fragColor = vec4(div, div, div, 1.0);
                break;
            case 2:
                // Visualize pressure as heat map
                float pressure = result.x;
                fragColor = vec4(pressure > 0.0 ? pressure : 0.0,
                               0.0,
                               pressure < 0.0 ? -pressure : 0.0,
                               1.0);
                break;
            case 3:
                // Visualize final velocity
                fragColor = vec4(abs(result.xy), 0.0, 1.0);
                break;
        }
    } else {
        fragColor = result;
    }
}`;

const params = {
    fragmentShader,
    vertexShader: vertexShaderNoMatrix,
    crossorigin: 'Anonymous',
    texturesOptions: {
        floatingPoint: 'half-float',
        premultiplyAlpha: true,
    },
    uniforms: {
        resolution: {
            name: 'uResolution',
            type: '2f',
            value: new Vec2(1080),
        },
        mousePos: {
            name: 'uMousePos',
            type: '2f',
            value: new Vec2(0.5),
        },
        previousMousePos: {
            name: 'uPreviousMousePos',
            type: '2f',
            value: new Vec2(0.5),
        },
        texelSize: {
            name: 'texelSize',
            type: '2f',
            value: new Vec2(1.0/1024.0)
        },
        dt: {
            name: 'dt',
            type: '1f',
            value: 0.016
        },
        dissipation: {
            name: 'dissipation',
            type: '1f',
            value: 0.98
        },
        strength: {
            name: 'uStrength',
            type: '1f',
            value: 0.5
        },
        scale: {
            name: 'uScale',
            type: '1f',
            value: 0.5
        },
        time: {
            name: 'uTime',
            type: '1f',
            value: 0.0
        },
        pass: {
            name: 'pass',
            type: '1i',
            value: 0
        },
        debug: {
            name: 'uDebug',
            type: '1i',
            value: 1
        }
    }
};

export const FLUID = {
    id: 'fluid',
    label: 'Fluid',
    params,
    aspectRatio: 1,
    passes: [
        {
            prop: 'pass',
            value: 0,  // Advection
            downSample: true,
            pingPong: true
        },
        {
            prop: 'pass',
            value: 1,  // Divergence
            downSample: true
        },
        {
            prop: 'pass',
            value: 2,  // Pressure solve
            downSample: true,
            pingPong: true
        },
        {
            prop: 'pass',
            value: 3,  // Gradient subtraction
            downSample: true
        }
    ],
    properties: {
        strength: {
            label: 'Strength',
            value: 0.5,
            min: 0,
            max: 1,
            step: 0.01,
            output: 'percent',
            tooltip: 'Controls the intensity of the fluid simulation'
        },
        scale: {
            label: 'Scale',
            value: 0.5,
            min: 0,
            max: 1,
            step: 0.01,
            output: 'percent',
            tooltip: 'Controls the scale of the fluid motion'
        },
        dissipation: {
            label: 'Dissipation',
            value: 0.98,
            min: 0.9,
            max: 0.999,
            step: 0.001,
            output: 'percent',
            tooltip: 'Controls how quickly the fluid motion fades'
        },
        speed: {
            label: 'Speed',
            header: 'Animation',
            value: 0.75,
            min: 0,
            max: 1,
            step: 0.01,
            output: 'percent',
            tooltip: 'Speed of the fluid motion'
        },
        debug: {
            label: 'Debug View',
            value: false,
            type: 'boolean',
            tooltip: 'Toggle debug visualization'
        }
    }
};
