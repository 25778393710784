<script>
import ColorInput from './ColorInput.vue';
import SliderParam from './SliderParam.vue';
import SizeInput from './SizeInput.vue';
import InputField from './InputField.vue';
import DropdownMenu from './DropdownMenu.vue';
import ParamLabel from './ParamLabel.vue';
import DisplacementControls from './DisplacementControls.vue';
import AlignmentControls from './AlignmentControls.vue';
import MaskControls from './MaskControls.vue';
import StateEffects from './StateEffects.vue';
import ClassicToggle from './ClassicToggle.vue';
import { BLEND_MODES } from '../scripts/Constants.js';
import { StudioStore } from '../stores/StudioStore.js';
import Input from './Input.vue';
import PanelNav from './PanelNav.vue';

const SHAPE_TYPES = {
  rectangle: 'Rectangle',
  circle: 'Circle',
  polygon: 'Polygon',
};

export default {
  components: {
    ColorInput,
    InputField,
    StateEffects,
    SliderParam,
    ParamLabel,
    DisplacementControls,
    AlignmentControls,
    MaskControls,
    SizeInput,
    ClassicToggle,
    DropdownMenu,
    Input,
    PanelNav,
  },
  data() {
    return {
      blendModes: BLEND_MODES,
      shapeTypes: SHAPE_TYPES,
      anchorOptions: {
        center: 'Center',
        topLeft: 'Top Left',
        topCenter: 'Top Center',
        topRight: 'Top Right',
        rightCenter: 'Right Center',
        bottomRight: 'Bottom Right',
        bottomCenter: 'Bottom Center',
        bottomLeft: 'Bottom Left',
        leftCenter: 'Left Center',
      },
      panelView: 'design',
      panelOptions: {
        design: 'Design',
        interactivity: 'Events'
      },
    };
  },
  created() {
    if(this.stateEffect) {
      this.panelView = 'interactivity';
    }
  },
  computed: {
    shape() {
      return StudioStore.getSelectedItem() || StudioStore.state.shape;
    },
    shapeWidth() {
      return Math.abs(this.shape.width[1] - this.shape.width[0]);
    },
    shapeHeight() {
      return Math.abs(this.shape.height[1] - this.shape.height[0]);
    },
    stateEffect() {
      return StudioStore.state.openStateEffect;
    }
  },
  watch: {
    stateEffect() {
      if(this.stateEffect) {
        this.panelView = 'interactivity';
      }
    }
  },
  methods: {
    getFillStyle(fill) {
      return getFillStyle(fill);
    },
    handleChange() {
      this.$emit('change');
    },
    handleBlendModePreview() {
      StudioStore.renderFrame();
    },
    handleBlendModeChange() {
      this.$emit('change');
    },
    handleColorChange(e, prop) {
      if (!(e instanceof Event)) {
        console.log(prop);
        this.shape[prop || 'fill'] = e;
        this.$emit('change');
      }
    },
  },
};
</script>

<template>
  <div class="parameters effect-properties control-section-wrapper">
    <div class="parameter parameter__block">
      <label class="parameter-label">Shape</label>
    </div>
    <PanelNav
      v-model="panelView"
      :options="panelOptions"
    />

    <template v-if="panelView === 'interactivity'">
      <StateEffects class="w-100" :item="shape" />
    </template>
    <template v-else-if="panelView === 'design'">
      <AlignmentControls :item="shape" @change="$emit('change')" />
  
      <MaskControls
        :item="shape"
        @change="$emit('change')"
        @edit-fill="$emit('edit-fill', { item: shape, prop: 'maskBackground' })"
      />
      <ClassicToggle
        label="Fit to artboard"
        v-model="shape.fitToCanvas"
        tooltip="Fits the shape to best fill the entire artboard for any given aspect ratio."
        @update:modelValue="$emit('input')"
      ></ClassicToggle>
      <div v-if="!shape.fitToCanvas" class="parameter parameter__block">
        <ParamLabel :props="['left', 'top']" label="Position" @reset-breakpoint-prop="$emit('update')">Position</ParamLabel>
        <div class="coords-input-group">
          <InputField label="X" v-model="shape.left" output="percent" :slider="true" @change="$emit('input')" />
          <InputField label="Y" v-model="shape.top" output="percent" :slider="true" @change="$emit('input')" />
        </div>
      </div>
      <div class="parameter parameter__block">
        <ParamLabel prop="anchorPoint" label="Anchor">Anchor</ParamLabel>
        <DropdownMenu
          :modelValue="shape.anchorPoint"
          :options="anchorOptions"
          prop="anchorPoint"
          @update="$emit('update')"
          @change="
            shape.setAnchorPoint($event);
            $emit('input');
          "
        ></DropdownMenu>
      </div>
      <SizeInput
        v-if="!shape.fitToCanvas"
        label="Width"
        v-model="shape.width"
        :mode="shape.widthMode"
        :alternateMode="shape.heightMode"
        :disableAuto="shape.widthMode === 'auto'"
        dimension="width"
        prop="width"
        @input="$emit('input')"
        @update="$emit('update')"
        @change-mode="shape.setDimensionMode('width', $event)"
      />
      <SizeInput
        v-if="!shape.fitToCanvas"
        label="Height"
        v-model="shape.height"
        :mode="shape.heightMode"
        :alternateMode="shape.widthMode"
        :disableAuto="shape.heightMode === 'auto'"
        dimension="height"
        prop="height"
        @input="$emit('input')"
        @update="$emit('update')"
        @change-mode="shape.setDimensionMode('height', $event)"
      />
      <SliderParam
        v-if="!shape.fitToCanvas"
        label="Rotation"
        v-model="shape.rotation"
        :min="0"
        :max="1"
        :step="0.0027"
        output="degrees"
        prop="rotation"
        @update="$emit('input')"
      ></SliderParam>
      <div class="parameter parameter__block">
        <ParamLabel prop="type" label="Shape type" @reset-breakpoint-prop="$emit('update')">Shape type</ParamLabel>
        <DropdownMenu
          v-model="shape.type"
          :options="shapeTypes"
          :rolloverPreview="true"
          @update:modelValue="$emit('input')"
        ></DropdownMenu>
      </div>
      <SliderParam
        label="Sides"
        v-if="shape.type === 'polygon'"
        v-model="shape.numSides"
        :min="3"
        :max="12"
        :step="1"
        prop="numSides"
        output="number"
        @update="$emit('input')"
      ></SliderParam>
      <div class="parameter parameter__block">
        <ParamLabel prop="fill" label="Fill" @reset-breakpoint-prop="$emit('update')">Fill</ParamLabel>
        <ColorInput
          :fill="shape.fill"
          :gradientType="shape.gradientType"
          @change="handleColorChange"
          prop="fill"
          @click-swatch="$emit('edit-fill', { item: shape })"
        ></ColorInput>
      </div>
      <div class="parameter parameter__block">
        <ParamLabel label="Stroke" :props="['stroke', 'strokeWidth']" @reset-breakpoint-prop="$emit('update')"
          >Stroke</ParamLabel
        >
        <div class="flex" style="width: var(--param-width)">
          <ColorInput
            :fill="shape.stroke"
            @change="handleColorChange($event, 'stroke')"
            prop="stroke"
            @click-swatch="$emit('edit-fill', { item: shape, prop: 'stroke' })"
          ></ColorInput>
          <InputField v-model="shape.strokeWidth" @change="$emit('input')" class="ml-1" output="px" />
        </div>
      </div>
      <SliderParam
        v-if="shape.type === 'rectangle'"
        label="Border radius"
        v-model="shape.borderRadius"
        :min="0"
        :max="1"
        :step="0.01"
        output="percent"
        prop="borderRadius"
        @update="$emit('input')"
      ></SliderParam>
      <SliderParam
        label="Opacity"
        v-model="shape.opacity"
        :min="0"
        :max="1"
        :step="0.01"
        output="percent"
        prop="opacity"
        @update="$emit('input')"
      ></SliderParam>
      <div class="parameter parameter__block">
        <label class="icon-label slider-label">Blend mode</label>
        <DropdownMenu
          v-model="shape.blendMode"
          :options="blendModes"
          :rolloverPreview="true"
          @update:modelValue="$emit('input')"
        ></DropdownMenu>
      </div>
      <DisplacementControls :item="shape" @input="$emit('input')" @change="$emit('change')"></DisplacementControls>
      <SliderParam
        label="Mouse tracking"
        header="Interactivity"
        v-model.number="shape.trackMouse"
        :min="0"
        :max="1"
        :step="0.01"
        output="percent"
        prop="trackMouse"
        @update="$emit('input')"
      ></SliderParam>
      <SliderParam
        label="Momentum"
        v-model.number="shape.mouseMomentum"
        tooltip="The amount of drag or delay of the track mouse effect"
        :min="0"
        :max="1"
        :step="0.01"
        output="percent"
        prop="mouseMomentum"
        @update="$emit('input')"
      ></SliderParam>
      <SliderParam
        label="3D axis tilt"
        v-model.number="shape.axisTilt"
        :min="0"
        :max="1"
        :step="0.01"
        @update="$emit('input')"
        prop="axisTilt"
        output="percent"
      ></SliderParam>
    </template>
  </div>
</template>
