<script>

export default {
  props: ["label", "modelValue", "value", "output", "min", "max", "step", "slider", "alpha", "disabled"],
  emits: ["update:modelValue", "change"],
  data() {
    return {
      holdingShift: false,
      displayValue: '',
      isDragging: false,
      startX: 0,
      startValue: 0,
    };
  },
  mounted() {
    this.setValue();
  },
  watch: {
    value() {
      this.setValue();
    },
    modelValue: {
      immediate: true,
      handler(newVal) {
        if(newVal && !isNaN(newVal)) {
          this.setValue(newVal);
        }
      }
    }
  },
  computed: {
    outputPadding() {
      let padding = this.output ? 1.6 : 0.6;
      if (this.output === "degrees" || this.output === "seconds") {
        padding = 0.8;
      } else if (this.output === "percent") {
        padding = 1.2;
      } else if(this.output === 'milliseconds') {
        padding = 1.7;
      } else if (this.output === "number") {
        padding = 0;
      }
      return {
        paddingRight: padding + "rem",
      };
    },
    unitOutput() {
      if (this.output === "seconds") {
        return "s";
      } else if (this.output === "milliseconds") {
        return "ms";
      } else if (this.output === "percent") {
        return "%";
      } else if (this.output === "degrees") {
        return "°";
      } else if (this.output === "number") {
        return "";
      } else {
        return "px";
      }
    },
  },
  methods: {
    setValue() {
      let translatedValue = this.modelValue !== undefined ? this.modelValue : this.value;
      if (isNaN(translatedValue) && translatedValue === 'Mixed') {
        this.displayValue = "Mixed";
      } else if (this.output === "string") {
        
        this.displayValue = translatedValue;
      } else {
        if (this.output === "percent") {
          this.displayValue = (translatedValue * 100).toFixed(1);
        } else if (this.output === "degrees") {
          this.displayValue = Math.round(translatedValue * 360);
        } else {
          this.displayValue = Math.round(translatedValue);
        }
      }
    },
    updateValue(value) {
      if (isNaN(value)) {
        if (value === 'Mixed') {
          this.displayValue = "Mixed";
        } else if (this.output === "string") {
          this.$emit("change", value);
          this.$emit('update:modelValue', value);
        }
      } else {
        let numValue = parseFloat(value);
        if (this.output === "percent") {
          numValue = numValue / 100;
        } else if (this.output === "degrees") {
          numValue = numValue / 360;
        }
        this.$emit("change", numValue);
        this.$emit('update:modelValue', numValue);
      }
    },

    handleStringUpdate(e) {
      this.$emit("change", this.stringValue);
      this.$emit('update:modelValue', this.stringValue);
    },

    handleKeyDown(e) {
      if(this.disabled || this.output === 'string') return;
      e = e ? e : window.event;
      const charCode = e.which ? e.which : e.keyCode;

      let val = this.modelValue || this.value;

      if(this.output === 'percent') {
        val = val * 100;
      }
      if(this.output === 'degrees') {
        val = val * 360;
      }

      if (charCode === 16) {
        this.holdingShift = true;
      }

      const increment = this.holdingShift ? 10 : 1;
      
      if (charCode === 38) {
        e.preventDefault();
        const newVal = parseFloat(val) + increment;
        this.updateValue(this.output === "percent" ? newVal.toFixed(1) : Math.round(newVal));
      } else if (charCode === 40) {
        e.preventDefault();
        const newVal = parseFloat(val) - increment;
        this.updateValue(this.output === "percent" ? newVal.toFixed(1) : Math.round(newVal));
      } else if (charCode === 13) {
        e.preventDefault();
        this.updateValue(parseFloat(this.displayValue));
        e.target.blur();
      } else {
        return true;
      }
    },

    handleKeyUp(e) {
      if(this.disabled || this.output === 'string') return;
      e = e ? e : window.event;
      const charCode = e.which ? e.which : e.keyCode;
      if (charCode === 16) {
        this.holdingShift = false;
      }
    },
    
    handleMouseMove(event) {
      if (!this.isDragging) return;
      
      const diffX = event.clientX - this.startX;
      const newValue = parseFloat(this.startValue) + diffX * (this.step || 1);
      
      if (this.output === "percent") {
        this.updateValue(newValue.toFixed(1));
      } else {
        this.updateValue(Math.round(newValue));
      }
    },
    
    handleMouseDown(event) {
      if(this.disabled || this.output === 'string') return;
      this.isDragging = true;
      this.startX = event.clientX;
      this.startValue = this.displayValue;
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);
    },
    
    handleMouseUp() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.handleMouseUp);
    },
  },
};
</script>

<template>
  <span class="parameter-output" :class="{ 'string': output === 'string', 'hidden-slider': output !== 'string', 'is__alpha': alpha, 'relative': output !== 'string' }">
    <span v-if="label && output !== 'string'" v-html="label" class="coords-label"></span>
    <input
      class="input-field"
      :class="{ 
        'input-field__label': label,
        'input-field__string': output === 'string'
      }"
      type="text"
      v-model="displayValue"
      :disabled="disabled"
      :style="outputPadding"
      @keydown="handleKeyDown"
      @keyup="handleKeyUp"
      @blur="updateValue(displayValue)"
      @mousedown="handleMouseDown"
    />
    <span v-if="output && output !== 'string'" class="output-units">{{ unitOutput }}</span>
  </span>
</template>

<style lang="scss">

.parameter-output {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 8.5rem;
  text-align: left;
  background-color: var(--accent-color);
  box-shadow: inset 0 -1px 0 var(--border-color);
  border-radius: 0.4rem;
}

.parameter-output.is__alpha {
  width: 5.5rem;
}

.parameter-output.string {
  width: var(--param-width);
  border-radius: 0.4rem;
}

.coords-label {
  position: absolute;
  left: 0.5rem;
  top: 0.7rem;
  color: var(--font-tertiary-color);
  user-select: none;
}

.hidden-slider {
  &:hover {
    .floating-slider {
      opacity: 1;
      left: 50%;
      transform: translateY(0) translateX(-50%);
    }
  }
}

.floating-slider {
  position: absolute;
  width: 10rem;
  opacity: 0;
  transform: translateY(0.5rem) translateX(-50%);
  left: -999em;
  top: -3rem;
  background-color: var(--artboard-color);
  z-index: 99;
  padding: 1.5rem 1rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.4rem 1rem rgba(0,0,0,0.15);
  transition: opacity 0.25s ease 0.25s, transform 0.25s ease 0.25s;
}

.input-field {
  height: 2rem;
  border: none;
  border-radius: 0.2rem;
  font: inherit;
  color: var(--font-color);
  width: 100%;
  text-align: right;
  background-color: transparent;
  cursor: ew-resize; // This indicates that the field can be dragged horizontally
  user-select: none; // Prevents text selection while dragging

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    outline: 0.2rem solid var(--primary-color);
    outline-offset: 0.2rem;
    color: var(--font-color);
  }
  &.input-field__label {
    padding-left: 1rem;
  }
  &.input-field__textarea {
    height: 5rem;
    resize: none;
    text-align: left;
    word-break: break-all;
    background-color: var(--accent-color);
    border-radius: 0.2rem;
    padding: 0.5rem;
    color: var(--font-color);
  }
  &.input-field__string {
    text-align: left;
    width: 100%;
    padding-left: 0;
    max-width: none;
    cursor: text;
  }
}

.parameter-output.is__breakpoint,
.is__breakpoint {
  background-color: var(--primary-color-50a);
}


.output-units {
  position: absolute;
  right: 0.5rem;
  top: 0.7rem;
  text-align: left;
  color: var(--font-secondary-color);
  pointer-events: none;
  user-select: none;
}

</style>