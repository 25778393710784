import { Element, getAnchorOffsets } from './Element.js';
import { Vec2 } from 'curtainsjs';
import { getShapeFill } from '../../scripts/Draw.js';
import { SCREEN_SIZES } from '../Constants.js';
import { deserializeNestedArray, serializeNestedArray } from '../../scripts/Helpers';
import { StudioStore } from '../../stores/StudioStore.js';

export class Shape extends Element {
  layerType = 'shape';

  constructor(args, id) {
    super(args, id);
    let props = this.getParams(args || {}).properties;

    for (let prop in props) {
      this[prop] = props[prop].value;
    }

    this.coords = [
      [0, 0],
      [this.width, 0],
      [this.width, this.height],
      [0, this.height],
    ];

    this.fill = args.fill || ['#777777'];
    this.fitToCanvas = args.fitToCanvas || false;
    this.gradientType = args.gradientType || 'linear';
    this.type = args.type || 'rectangle';
    this.stroke = args.stroke || ['#000000'];
    this.numSides = args.numSides || 3;

    if (Object.keys(args).length) {
      this.createLocalCanvas();
    }

    if(!('left' in args) && !('top' in args)) {
      this.handleBackCompat(args);
    }

    this.local.propertiesToWatch = [
      'anchorPoint',
      'borderRadius',
      'rotation',
      'fill',
      'left',
      'leftMode',
      'top',
      'topMode',
      'gradientAngle',
      'gradientType',
      'type',
      'fitToCanvas',
      'opacity',
      'numSides',
      'trackMouse',
      'mouseMomentum',
      'strokeWidth',
      'stroke',
      'width',
      'widthMode',
      'height',
      'heightMode',
    ];
  }

  default(args) {
    return {
      pos: {
        label: 'Position',
        value: args.pos || new Vec2(0.5),
        min: 0,
        max: 1,
        step: 0.01,
        output: 'percent',
      },
      borderRadius: {
        label: 'Border radius',
        value: args.borderRadius || 0,
        min: 0,
        max: 1,
        step: 0.01,
        output: 'percent',
      },
      gradientAngle: {
        label: 'Gradient angle',
        value: args.gradientAngle || 0,
        min: 0,
        max: 1,
        step: 0.0027,
        output: 'degrees',
      },
      strokeWidth: {
        label: 'Stroke width',
        value: args.strokeWidth || 0,
        min: 0,
        max: 100,
        step: 1,
        output: 'pixels',
      },
    };
  }

  handleDimensionsBackCompat(args, ratio) {
    let signedWidth = 0;
    let signedHeight = 0;

    // Handle first generation (coords array)
    if (args.coords && deserializeNestedArray(args.coords).length) {
      signedWidth = args.coords[0][0] - args.coords[1][0];
      signedHeight = args.coords[2][1] - args.coords[1][1];
    }
    // Handle second generation (width/height arrays)
    else {
      if (args.width?.length) {
        signedWidth = args.width[1] - args.width[0];
      }
      if (args.height?.length) {
        signedHeight = args.height[1] - args.height[0];
      }
      if (!args.width?.length && !args.height?.length) {
        return false;
      }
    }

    signedWidth *= ratio;
    signedHeight *= ratio;

    return {
      width: Math.abs(signedWidth),
      height: Math.abs(signedHeight),
      signedWidth,
      signedHeight
    };
  }

  handleBackCompat(args) {
    this.translateX = args.translateX || null;
    this.translateY = args.translateY || null;

    let { bcWidth, bcHeight, ratio } = this.getBackCompatData();
    const dimensions = this.handleDimensionsBackCompat(args, ratio);
    if(dimensions) {
      this.width = dimensions.width;
      this.height = dimensions.height;
    }
    const offsetPosition = this.getPositionOffset();
    this.left = offsetPosition.x / bcWidth;
    this.top = offsetPosition.y / bcHeight;
    this.left += dimensions.signedWidth / StudioStore.state.canvasWidth / 2;
    this.top += dimensions.signedHeight / StudioStore.state.canvasHeight / 2;

    this.breakpoints.forEach(breakpoint => {
      let size = SCREEN_SIZES.find(n => n.name === breakpoint.name);
      let { bcWidth, bcHeight, ratio } = this.getBackCompatData(size.dimensions[0], size.dimensions[1]);
      const bpDimensions = this.handleDimensionsBackCompat(breakpoint.props, ratio);
      if ('width' in breakpoint.props || 'height' in breakpoint.props) {
        if ('width' in breakpoint.props) {
          breakpoint.props.width = bpDimensions.width/StudioStore.state.canvasWidth;
          breakpoint.props.widthMode = 'relative';
        }
        if ('height' in breakpoint.props) {
          breakpoint.props.height = bpDimensions.height/StudioStore.state.canvasHeight;
          breakpoint.props.heightMode = 'relative';
        }
      }

      if('translateX' in breakpoint.props) {
        const bpOffsetPosition = this.getPositionOffset(breakpoint.props.translateX, breakpoint.props.translateY, size.dimensions[0], size.dimensions[1]);
        breakpoint.props.left = bpOffsetPosition.x / bcWidth;
        breakpoint.props.left += (bpDimensions.signedWidth || (breakpoint.props.width || this.width)) / size.dimensions[0] / 2;
        breakpoint.props.leftMode = 'relative';
        delete breakpoint.props.translateX;
      }
      if('translateY' in breakpoint.props) {
        const bpOffsetPosition = this.getPositionOffset(breakpoint.props.translateX, breakpoint.props.translateY, size.dimensions[0], size.dimensions[1]);
        breakpoint.props.top = bpOffsetPosition.y / bcHeight;
        breakpoint.props.top += (bpDimensions.signedHeight || (breakpoint.props.height || this.height)) / size.dimensions[1] / 2;
        breakpoint.props.topMode = 'relative';
        delete breakpoint.props.translateY;
      }

    });
    
    this.setDimensionMode('width', 'relative');
    this.setDimensionMode('height', 'auto');
  }

  packageType(skipSerialization) {
    return {
      layerType: 'shape',
      visible: this.visible,
      borderRadius: this.borderRadius,
      fill: serializeNestedArray(this.fill, skipSerialization),
      fitToCanvas: this.fitToCanvas,
      gradientAngle: this.gradientAngle,
      gradientType: this.gradientType,
      numSides: this.numSides,
      stroke: serializeNestedArray(this.stroke, skipSerialization),
      strokeWidth: this.strokeWidth,
      type: this.type,
    };
  }

  unpackageType() {
    this.fill = deserializeNestedArray(this.fill);
    this.stroke = deserializeNestedArray(this.stroke);
    this.coords = deserializeNestedArray(this.coords);

    return this;
  }

  render() {
    const { left, top, width, height } = this.box();
    const anchorOffsets = getAnchorOffsets(this.anchorPoint);

    let x = left - anchorOffsets.x * width;
    let y = top - anchorOffsets.y * height;

    let borderRadius = this.local.stateEffectProps?.borderRadius ?? this.borderRadius;
    let strokeWidth = this.local.stateEffectProps?.strokeWidth ?? this.strokeWidth;
    let gradientAngle = this.local.stateEffectProps?.gradientAngle ?? this.gradientAngle;

    this.local.ctx.clearRect(0, 0, StudioStore.state.canvasWidth, StudioStore.state.canvasHeight);
    this.local.ctx.beginPath();

    if (!this.fitToCanvas) {
        this.local.ctx.save();
        this.local.ctx.translate(x + width / 2, y + height / 2);
        this.local.ctx.rotate(this.rotation * 2 * Math.PI);
        this.local.ctx.translate(-width / 2, -height / 2);

        if (this.type === 'rectangle') {
            let borderRadiusAmount = (borderRadius * Math.min(Math.abs(width), Math.abs(height))) / 2;
            this.drawRoundedRect(this.local.ctx, 0, 0, width, height, borderRadiusAmount);
        } else if (this.type === 'circle') {
            this.local.ctx.ellipse(width / 2, height / 2, Math.abs(width) / 2, Math.abs(height) / 2, 0, 0, 2 * Math.PI);
        } else if (this.type === 'polygon') {
            this.drawPolygon(this.local.ctx, Math.abs(width/2), Math.abs(height/2), this.numSides);
        }
    } else {
        this.local.ctx.rect(0, 0, StudioStore.state.canvasWidth, StudioStore.state.canvasHeight);
    }

    this.local.ctx.fillStyle = getShapeFill(this.local.ctx, this.box(), this.fill, gradientAngle, this.gradientType);
    this.local.ctx.fill();

    if (strokeWidth) {
      this.local.ctx.strokeStyle = this.stroke[0];
      this.local.ctx.lineWidth = strokeWidth;
      this.local.ctx.stroke();
    }

    this.local.ctx.restore();
  }

  drawRoundedRect(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
  }

  drawPolygon(ctx, width, height, sides) {
    ctx.beginPath();
    for (let i = 0; i < sides; i++) {
      const angle = (i * 2 * Math.PI) / sides - Math.PI / 2;
      const x = width * Math.cos(angle) + width;
      const y = height * Math.sin(angle) + height;
      i === 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y);
    }
    ctx.closePath();
  }
}
