<script>
import axios from 'axios';
import { SCREEN_SIZES } from '../scripts/Constants.js';
import { getFillStyle } from '../scripts/ColorHelpers.js';
import { CreateCommand, UpdateCommand, RemoveStateEffectCommand } from '../scripts/Commands.js';
import { TextBox } from '../scripts/layerTypes/Text.js';
import { Shape } from '../scripts/layerTypes/Shape.js';
import { Img } from '../scripts/layerTypes/Image.js';
import { Effect } from '../scripts/layerTypes/Effect.js';
import { formatDate, Debouncer, generateUUID, compressThumbnail } from '../scripts/Helpers.js';
import Artboard from '../components/Artboard.vue';
import Button from '../components/Button.vue';
import CanvasUI from '../components/CanvasUI.vue';
import ClassicToggle from '../components/ClassicToggle.vue';
import ColorBrowser from '../components/ColorBrowser.vue';
import ColorInput from '../components/ColorInput.vue';
import CoordInput from '../components/CoordInput.vue';
import MultiSelectControls from '../components/MultiSelectControls.vue';
import DropdownMenu from '../components/DropdownMenu.vue';
import EffectControls from '../components/EffectControls.vue';
import HistoryList from '../components/HistoryList.vue';
import SDFShapeBrowser from '../components/SDFShapeBrowser.vue';
import HistoryListItem from '../components/HistoryListItem.vue';
import ImageControls from '../components/ImageControls.vue';
import ImagesBrowser from '../components/ImagesBrowser.vue';
import InputField from '../components/InputField.vue';
import RadioToggle from '../components/RadioToggle.vue';
import Icon from '../components/Icon.vue';
import ShapeControls from '../components/ShapeControls.vue';
import StateEffectAppear from '../components/StateEffectAppear.vue';
import StateEffectScroll from '../components/StateEffectScroll.vue';
import StateEffectHover from '../components/StateEffectHover.vue';
import SliderParam from '../components/SliderParam.vue';
import TextControls from '../components/TextControls.vue';
import VersionItem from '../components/VersionItem.vue';
import AssetItem from '../components/AssetItem.vue';
import ExportWindow from '../components/ExportWindow.vue';
import EffectBrowser from '../components/EffectBrowser.vue';
import FontsBrowser from '../components/FontsBrowser.vue';
import Editor from '../components/Editor.vue';
import TopBar from '../components/TopBar.vue';
import Timeline from '../components/Timeline.vue';
import ProModal from '../components/ProModal.vue';
import { DesignsStore } from '../stores/DesignsStore.js';
import { FirebaseStore } from '../stores/FirebaseStore.js';
import { StudioStore, unpackageHistory } from '../stores/StudioStore.js';
import { UserStore } from '../stores/UserStore.js';
import { logsnagTrack } from '../scripts/LogSnag.js';
import { auth } from '../firestore/init.js';
import { Vec2 } from 'curtainsjs';

const LEFTRAIL_WIDTH = 0;

let hidden, visibilityChange;
let copiedItem;

if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

function roundXToNearestY(x, y) {
  return Math.ceil(x / y) * y;
}

function weightedLerp(current, last, amount) {
  for (let i = 0; i < amount; i++) {
    current = (current + last) / 2;
  }
  return +((current + last) / 2).toFixed(2);
}

function packageVersion(version) {
  return version.map(item => item.package());
}

// Check for Safari and available MIME types
const getSupportedMimeType = () => {
  const types = [
    "video/webm;codecs=h264",
    "video/webm;codecs=vp9",
    "video/webm",
    "video/mp4"
  ];
  
  return types.find(type => MediaRecorder.isTypeSupported(type)) || '';
};

export default {
  components: {
    Artboard,
    Button,
    CanvasUI,
    ClassicToggle,
    ColorBrowser,
    ColorInput,
    CoordInput,
    DropdownMenu,
    EffectControls,
    EffectBrowser,
    Editor,
    FontsBrowser,
    HistoryList,
    HistoryListItem,
    MultiSelectControls,
    ImageControls,
    ImagesBrowser,
    InputField,
    Icon,
    RadioToggle,
    ShapeControls,
    StateEffectAppear,
    StateEffectHover,
    StateEffectScroll,
    SliderParam,
    SDFShapeBrowser,
    TextControls,
    Timeline,
    VersionItem,
    ExportWindow,
    ProModal,
    TopBar,
    AssetItem,
  },
  props: ['designs'],
  data() {
    return {
      state: StudioStore.state,
      fastRedrawDebouncer: new Debouncer({
        fn: this.fastRedraw,
        interval: 16,
      }),
      fontFamilies: {},
      fontData: {},
      loadingStudio: true,
      undoStateUpdater: new Debouncer({
        fn: this.updateUndoState,
        interval: 100,
        immediate: true,
      }),
      sizes: SCREEN_SIZES,
      versions: [],
    };
  },
  mounted() {
    this.loadStudio();
  },
  unmounted() {
    document.removeEventListener(visibilityChange, this.handleVisibilityChange, false);
    window.removeEventListener('focus', this.handleWindowFocus);
    //window.removeEventListener('paste', this.handleWindowPaste);
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('mousedown', this.handleWindowMouseDown);
    window.removeEventListener('mouseup', this.handleWindowMouseUp);
    window.removeEventListener('mousemove', this.handleWindowMouseMove);

    this.state.hotkeys.unbind();

    document.body.style.overflow = 'auto';
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);

    this.state.selectedItems = [];
    this.state.actionHistory = [];
    this.state.mouse.trail = [];
    this.state.undoPointer = -1;
    this.state.customCodeItemId = null;
    this.state.export.active = false;

    this.state.mainMenuOpen = false;
    this.state.initialChangeStates.length = 0;
    this.state.initialized = false;
    this.state.loading = true;
    if(this.state.curtain) {
      this.state.curtain.dispose();
    }
  },
  watch: {
    designId() {
      if (!this.loadingStudio) {
        this.state.selectedItems = [];
        this.state.actionHistory = [];
        this.state.mouse.trail = [];
        this.state.undoPointer = -1;
        this.state.customCodeItemId = null;

        this.state.mainMenuOpen = false;
        this.state.initialChangeStates.length = 0;
        this.state.initialized = false;
        this.state.loading = true;
        this.state.design = {}
        if(this.state.curtain) {
          this.state.curtain.dispose();
        }
        this.loadStudio();
      }
    },
    'state.size'() {
      if (this.state.initialized) {
        this.handleCanvasResize();
      }
    },
    'state.customCodeItemId'() {
      this.fitCanvas();
    },
    'state.previewing'() {
      if (this.state.previewing) {
        this.$router.push({ query: { ...this.$route.query, preview: 'true' } });
        this.setUserZoom('fit');
      } else {
        const { preview, ...otherQueries } = this.$route.query;
        this.$router.push({ query: otherQueries });
      }
    },
    editingItem() {
      if (this.editingItem && this.state.colorPicker.item) {
        if (!StudioStore.isSelected(this.state.colorPicker?.item) && this.editingItem[this.state.colorPicker.prop]) {
          this.state.colorPicker.item = this.editingItem;
        } else {
          this.state.colorPicker.item = null;
        }
      }
    },
    'state.animatingEffects'() {
      if (!this.state.export.downloading) {
        this.playAnimation();
      }
    },
    tool() {
      if (this.state.editingItem) {
        StudioStore.setSelectedItem('');
      }
    },
  },
  computed: {
    designId() {
      return this.$route.params.designId;
    },
    currentSize() {
      return this.state.currentSize;
    },
    computedScale() {
      return this.state.scale * this.state.canvasDpi;
    },
    canvasWidth() {
      return Math.round(this.state.currentSize.dimensions[0]);
    },
    canvasHeight() {
      return Math.round(this.state.currentSize.dimensions[1]);
    },
    canvasPlaneStyle() {
      return {
        left: this.state.loading ? '-999rem' : '0',
        transform: `translate3d(0px, 0px, 1px)`,
        transition: 'opacity 0.2s ease',
      };
    },
    zoomStyle() {
      return {
        width: this.canvasWidth * this.state.userZoom + 'px',
        height: this.canvasHeight * this.state.userZoom + 'px',
        opacity: this.state.loading ? 0 : 1,
        background: this.state.previewing ? 'none' : '',
        transform: `translate3d(${this.state.panLeft}px, ${this.state.panTop}px, 1px)`,
      };
    },
    outputStyle() {
      return {
        position: 'absolute',
        transform: `translate3d(0px, 0px, 1px)`,
      };
    },
    canvasDpi() {
      return window.devicePixelRatio || 1;
    },

    backgroundHistoryItem() {
      return this.state.history.find(n => n.isBackground);
    },
    editingItem() {
      return StudioStore.getSelectedItem();
    },
    animatingEffects() {
      return this.historyEffects.filter(n => n.animating && n.visible);
    },
    historyEffects() {
      return this.state.history.filter(n => n.layerType === 'effect');
    },
    historyUIItems() {
      return this.state.history.filter(n => n.visible && n.layerType !== 'effect' && n.layerType !== 'fill');
    },
    historyColors() {
      const keychain = {};
      const results = [];
      this.state.history.map(n => {
        if (n.fill && !keychain[n.fill.toString()]) {
          keychain[n.fill.toString()] = n.fill;
          results.push(n.fill);
        }
      });
      return results;
    },
    isViewer() {
      return this.$route.params.mode === 'view';
    },
    isEditing() {
      return this.$route.params.mode === 'edit';
    },
    loading() {
      return !UserStore.id || DesignsStore.loadingDesigns;
    },
    tallyLink() {
      return `https://tally.so#tally-open=wzNdr8&tally-emoji-text=🦄&tally-emoji-animation=wave&email=${UserStore.email}`;
    },
    showTopBar() {
      return !this.state.customCodeItemId;
    },
    showCanvasUI() {
      return !this.state.export.active && 
        !this.state.previewing && 
        this.state.initialized && 
        !this.state.browsingEffects && 
        !this.state.browsingFonts && 
        !this.state.browsingSizes && 
        !this.state.openStateEffect && 
        !this.state.imagesBrowser.open && 
        !this.state.signUpForPro && 
        !this.state.colorPicker.item &&
        !this.state.sdfShapeBrowser
    },
    showLeftRail() {
      return !this.state.customCodeItemId && !this.state.previewing;
    },
    showHistoryContainer() {
      return !StudioStore.state.previewing && !this.state.customCodeItemId && !this.state.export.active
    },
    showTimeline() {
      return (
          !this.state.previewing && this.state.initialized && StudioStore.state.history.some(item => item.states.appear.length)
        );
    },
  },
  methods: {
    loadStudio() {
      this.loadingStudio = true;
      StudioStore.state.history = [];
      let hd = localStorage.getItem('unicornstudio-hd');
      if (hd === 'false') {
        StudioStore.state.hd = false;
        StudioStore.state.scale = 0.6666666666;
      }

      this.fitCanvas();
      StudioStore.init(this.$route.params.designId, () => {
        this.state.history.forEach(item => {
          item.initializeBreakpoints();
        });

        StudioStore.createCurtains();
        StudioStore.handleItemPlanes(() => {
          this.initializeStudio(() => {
            this.state.initialized = true;
            this.state.loading = false;
            this.loadingStudio = false;

            StudioStore.fullRedraw();

            if (this.state.animatingEffects.length) {
              this.playAnimation();
            } else {
              StudioStore.renderNFrames(2);
            }

            if (this.$route.query.preview) {
              StudioStore.handlePreview();
            }

            this.fitCanvas();
          });
        });
      });
    },
    initializeStudio(callback) {
      this.$nextTick(() => {
        document.body.style.overflow = 'hidden';
        window.scrollTo(0, 0);
        this.bindEvents();
        callback();
      });
    },
    handleCanvasResize() {
      this.setUserZoom('fit');
      this.$nextTick(() => {
        if (this.state.history.length) {
          this.state.curtain.setPixelRatio(StudioStore.getPixelRatio());
          this.state.history.forEach(item => {
            if (item.resize) {
              item.resize();
            }
            if (item.render) {
              item.render();
            }
          });
          this.state.curtain.resize();
          StudioStore.renderFrame();

          this.state.drawUIUpdater++;

          StudioStore.saveDesignDebounced(null, true);
        }
      });
    },
    bindEvents() {
      document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
      window.addEventListener('blur', this.handleWindowBlur);
      window.addEventListener('focus', this.handleWindowFocus);
      //window.addEventListener('paste', this.handleWindowPaste);
      window.addEventListener('resize', this.handleWindowResize);
      window.addEventListener('mousemove', this.handleWindowMouseMove);
      window.addEventListener('mousedown', this.handleWindowMouseDown);
      window.addEventListener('mouseup', this.handleWindowMouseUp);

      this.state.hotkeys(
        'cmd+[,cmd+],cmd+a,cmd+x,up,ctrl,space,backspace,esc,cmd,down,left,right,cmd+c,shift+up,shift+down,shift+left,shift+right,v,r,b,o,=,-,ctrl+z,cmd,cmd+v,cmd+z,cmd+d,shift+1,shift+cmd+z,shift+ctrl+z,shift+cmd+h,h,r,s,escape,z,d,p,alt,cmd+shift+x,*',
        (event, handler) => {
          const selected = StudioStore.getSelectedItem();
          const tagName = (event.target || event.srcElement).tagName;
          switch (handler.key) {
            case '*':
              const holdingShift = this.state.hotkeys.isPressed('shift');
              if (this.state.browsingFonts) {
                return false;
              }

              if (this.state.hotkeys.isPressed('up')) {
                if (tagName !== 'INPUT') {
                  this.nudgeItem('up', holdingShift ? -10 : -1);
                }
              }
              if (this.state.hotkeys.isPressed('down')) {
                if (tagName !== 'INPUT') {
                  this.nudgeItem('down', holdingShift ? 10 : 1);
                }
              }
              if (this.state.hotkeys.isPressed('left')) {
                if (tagName !== 'INPUT') {
                  this.nudgeItem('left', holdingShift ? -10 : -1);
                }
              }
              if (this.state.hotkeys.isPressed('right')) {
                if (tagName !== 'INPUT') {
                  this.nudgeItem('right', holdingShift ? 10 : 1);
                }
              }
              break;
            case 'cmd+a':
              event.preventDefault();
              this.state.selectedItems = this.state.history.filter(n => n.isElement);
              break;
            case 'cmd+[':
              event.preventDefault();
              if (this.editingItem) {
                const currentIndex = this.editingItem.getIndex();
                this.editingItem.moveToPosition(currentIndex - 1, currentIndex);
                StudioStore.saveDesignDebounced();
              }
              break;
            case 'cmd+]':
              event.preventDefault();
              if (this.editingItem) {
                const currentIndex = this.editingItem.getIndex();
                this.editingItem.moveToPosition(currentIndex + 1, currentIndex);
                StudioStore.saveDesignDebounced();
              }
              break;
            case 'esc':
              if (StudioStore.state.previewing) {
                StudioStore.state.previewing = false;
                this.setUserZoom('fit');
              }
              StudioStore.state.customCodeItemId = '';
              StudioStore.state.openStateEffect = null;
              break;
            case 'ctrl+z':
              event.preventDefault();
              StudioStore.undo();
              StudioStore.state.drawUIUpdater++;
              this.updateEffectValue();
              break;
            case 'cmd+z':
              event.preventDefault();
              StudioStore.undo();
              StudioStore.state.drawUIUpdater++;
              this.updateEffectValue();
              break;
            case 'cmd+c':
              if (selected && !selected.isBackground) {
                DesignsStore.state.copiedItem = selected;
              }

              break;
            case 'cmd+v':
              // trhottle
              if (DesignsStore.state.copiedItem) {
                StudioStore.addItem(DesignsStore.state.copiedItem.copy());
              }
              break;
            case 'cmd+x':
              if (selected) {
                this.deleteHistoryItem(selected);
              }
              break;
            case 'backspace':
              if (StudioStore.state.openStateEffect) {
                StudioStore.performAction(new RemoveStateEffectCommand(selected, StudioStore.state.openStateEffect.id));
                StudioStore.state.openStateEffect = null;
              } else if (selected) {
                this.deleteHistoryItem(selected);
              }
              break;
            case 'cmd+d':
              event.preventDefault();
              StudioStore.duplicateItem();
              break;
            case 'shift+1':
              this.setUserZoom('fit');
              break;
            case '=':
              this.setUserZoom('in');
              break;
            case '-':
              this.setUserZoom('out');
              break;
            case 'shift+cmd+z':
              event.preventDefault();
              StudioStore.redo();
              StudioStore.state.drawUIUpdater++;
              this.updateEffectValue();
              break;
            case 'shift+ctrl+z':
              event.preventDefault();
              StudioStore.redo();
              StudioStore.state.drawUIUpdater++;
              this.updateEffectValue();
              break;
            case 'shift+cmd+h':
              if (this.editingItem) {
                event.preventDefault();
                this.editingItem.toggleVisibility();
              }
              break;
            case 'v':
              this.state.tool = 'selector';
              break;
            case 'r':
              this.state.tool = 'shape';
              this.state.shape.type === 'rectangle';
              break;
            case 'o':
              this.state.tool = 'shape';
              this.state.shape.type === 'circle';
              break;
            case 'b':
              this.state.tool = 'brush';
              break;
            case 'p':
              StudioStore.handlePreview();
              this.fitCanvas();
              break;
            case 'escape':
              StudioStore.setSelectedItem('');
              if (this.state.customCodeItemId) {
                this.state.customCodeItemId = null;
              }
              break;
            case 'space':
              if (selected && 'animating' in selected) {
                selected.toggleProp('animating');
              }
              break;
            case 'cmd+shift+x':
              this.clearAll();
              break;
            default:
          }
        }
      );

      let commandispress = false;
      let wasfired = false;

      this.state.hotkeys.filter = event => {
        var target = event.target || event.srcElement;
        var tagName = target.tagName;

        if (this.state.customCodeItemId) {
          return false;
        }

        // Track the state of the Command and Control keys
        if (event.key === 'Meta' || event.key === 'Control') {
          commandispress = event.type === 'keydown'; // Set true on keydown, false on keyup
        }

        // Check for "cmd+z" and "ctrl+z"
        const isCmdZ = commandispress && event.key.toLowerCase() === 'z';

        // Allow cmd+z, ctrl+z, and escape in input fields, but block other keys
        if (['INPUT', 'TEXTAREA', 'SELECT'].includes(tagName) && !['Escape'].includes(event.key) && !isCmdZ) {
          return false;
        }

        // Allow all other events to pass through unless specifically blocked
        wasfired = isCmdZ || wasfired;

        return true; // Allow the event to pass through
      };

      this.state.isHotKeyRegistered = true;

      this.$el.querySelector('.draw-container').addEventListener('wheel', this.handleWheel);
      this.$el.querySelector('.draw-container').addEventListener('mousedown', this.handleDrawStart);
    },

    handleVisibilityChange() {
      if (document[hidden]) {
        cancelAnimationFrame(StudioStore.state.curtainRafId);
      } else {
        this.playAnimation();
      }
    },

    handleWindowPaste(e) {
      if (this.state.customCodeItemId) {
        return false;
      }
      const clipboardData = e.clipboardData || window.clipboardData;
      if (!clipboardData) {
        return;
      }

      const items = clipboardData.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') === 0) {
            const imageBlob = items[i].getAsFile();

            if (imageBlob) {
              var img = new Image();

              img.onload = () => {
                const imageItem = new Img({
                  src: URL.createObjectURL(imageBlob),
                  thumb: URL.createObjectURL(imageBlob),
                });

                const command = new CreateCommand(imageItem);
                StudioStore.performAction(command);

                DesignsStore.uploadImage(null, imageBlob).then(({ src, thumb }) => {
                  imageItem.src = src;
                  imageItem.thumb = thumb;
                  StudioStore.save();
                });
              };
              var URL = window.URL || window.webkitURL;
              img.src = URL.createObjectURL(imageBlob);
            }
          }
        }
      }
    },
    handleWindowMouseMove(e) {
      this.state.mouse.delta.x = Math.round(this.state.mouse.movePos.x - this.state.mouse.downPos.x);
      this.state.mouse.delta.y = Math.round(this.state.mouse.movePos.y - this.state.mouse.downPos.y);
      this.handleMouseMove(e);

      if (!this.state.mouse.moved && !this.state.loading) {
        this.state.mouse.moved = true;
      }
    },
    handleWheel(e) {
      e.preventDefault();
      if (this.state.hotkeys.isPressed('cmd')) {
        this.state.userZoom = this.state.userZoom * Math.exp(-e.deltaY / 200);
      } else {
        // Simplified panning logic
        this.state.panLeft -= e.deltaX;
        this.state.panTop -= e.deltaY;
        this.state.drawUIUpdater++;
      }
    },
    handleDrawStart(e) {
      if (e.which !== 3 && !this.editingItem && this.state.tool !== 'selector') {
        this.state.drawing = true;
      }

      if (this.state.hotkeys.isPressed('alt')) {
        this.state.drawing = false;
        this.state.userZoom = 0.8 * this.state.userZoom;
      }
      if (this.state.hotkeys.isPressed('z')) {
        this.state.drawing = false;
        this.state.userZoom = 1.2 * this.state.userZoom;
      }

      this.state.mouse.downPos.x = this.state.mouse.movePos.x;
      this.state.mouse.downPos.y = this.state.mouse.movePos.y;

      if (this.state.drawing) {
        let item;
        if (this.state.tool === 'shape') {
          item = new Shape({
            aspectRatio: StudioStore.state.currentSize.aspectRatio,
            fill: StudioStore.state.shape.fill,
            anchorPoint: 'topLeft',
            gradientAngle: StudioStore.state.shape.gradientAngle,
            gradientType: StudioStore.state.shape.gradientType,
            type: StudioStore.state.shape.type,
            left: this.state.mouse.pos.x / StudioStore.state.canvasWidth,
            top: this.state.mouse.pos.y / StudioStore.state.canvasHeight,
            width: 2,
            height: 2,
          });
        } else if (this.state.tool === 'text') {
          item = new TextBox({
            left: this.state.mouse.pos.x / StudioStore.state.canvasWidth,
            top: this.state.mouse.pos.y / StudioStore.state.canvasHeight,
            width: 5,
            height: 40,
            fontSize: 60,
            lineHeight: 60,
            anchorPoint: 'topLeft',
            fill: ['#777777'],
          });
          item.justCreated = true;
        }
        if (item) {
          const command = new CreateCommand(item);
          StudioStore.performAction(command);
        }
      }
    },
    handleMouseMove(e) {
      const offsetLeft =
        this.state.panLeft +
        LEFTRAIL_WIDTH -
        (this.state.customCodeItemId ? -210 : 0) +
        (window.innerWidth - LEFTRAIL_WIDTH * 2 - this.canvasWidth * this.state.userZoom) / 2;
      const offsetTop = this.state.panTop + (window.innerHeight / 2 - (this.canvasHeight * this.state.userZoom) / 2);

      const xPos = (e.pageX - offsetLeft) / (this.canvasWidth * this.state.userZoom);
      const yPos = (e.pageY - offsetTop) / (this.canvasHeight * this.state.userZoom);

      if (this.state.previewing || this.showCanvasUI) {
        if (xPos > 0 && yPos > 0 && xPos < 1 && yPos < 1) {
          if (!this.state.mouse.enterTime) {
            this.state.mouse.enterTime = performance.now();
          }
        } else {
          this.state.mouse.enterTime = null;
        }
      }

      if (!this.state.tick) this.state.tick = requestAnimationFrame(this.render);
      this.state.mouse.movePos.x = Math.round((e.pageX - offsetLeft) / this.state.userZoom);
      this.state.mouse.movePos.y = Math.round((e.pageY - offsetTop) / this.state.userZoom);

      if (this.state.drawing) {
        this.fastRedraw();
      }
    },
    handleWindowResize() {
      if (!this.state.previewing) {
        this.setUserZoom('fit');
      }
      this.$nextTick(() => {
        this.fastRedraw();
      });
    },
    handleWindowMouseDown(e) {
      this.state.mouse.dragging = true;
      this.state.mouse.delta.x = 0;
      this.state.mouse.delta.y = 0;

      if (!e.target.closest('.draw-container')) {
        this.state.tool = 'selector';
      }
    },
    handleWindowMouseUp(e) {
      const item = this.state.history.at(-1);

      if (item?.layerType === 'shape' && item?.widthMode === 'fixed' && !(item?.width > 2 || item?.height > 2)) {
        item.width = 200;
        item.height = 200;
        item.pos = new Vec2(
          this.state.mouse.downPos.x / this.state.canvasWidth,
          this.state.mouse.downPos.y / this.state.canvasHeight
        );
      }

      if (this.state.drawing) {
        this.state.drawing = false;
        this.state.tool = 'selector';
        item.local.isSelected = true;
        item.render();
        this.fastRedraw();
        StudioStore.saveDesignDebounced(null, true);
      } else {
        if(this.editingItem && e.target?.closest) {
          if(
            !e.target.closest('.edit-item-container') && 
            !e.target.closest('.history-item-container') && 
            !e.target.closest('.color-container') &&
            !e.target.closest('.draw-editor') &&
            !e.target.closest('.browse-effects') &&
            !e.target.closest('.editor-container') &&
            !e.target.closest('.effect-properties') &&
            !e.target.closest('.modal__image-library') &&
            !e.target.closest('.modal__effects') &&
            !e.target.closest('.modal__state-effect') &&
            !e.target.closest('.shape-controls') &&
            !e.target.closest('.modal__prompt') &&
            !e.target.closest('.breakpoint-button') &&
            !e.target.closest('.shape-controls') && 
            !e.target.closest('.timeline-container')
          ) {
            if (
              (((this.editingItem.layerType !== 'effect' && !this.editingItem.effects) ||
                !this.editingItem.effects.length) &&
                this.editingItem.layerType !== 'image' &&
                !e.target.closest('.draw-container')) ||
              ((this.editingItem.layerType === 'effect' || this.editingItem.layerType === 'image') &&
                !e.target.closest('.effect-properties') &&
                !e.target.closest('.position-adjuster-container'))
            ) {
              if (!this.state.browsingFonts) {
                StudioStore.setSelectedItem('');
              }
            }
          }
        }
      }

      this.state.mouse.dragging = false;

      this.$nextTick(() => {
        this.state.mouse.delta.x = 0;
        this.state.mouse.delta.y = 0;
      });
    },
    clearAll() {
      StudioStore.clearAll();
    },
    handleBackgroundChange(fill) {
      this.backgroundHistoryItem.fill = fill;
      this.backgroundHistoryItem.render();
      StudioStore.renderNFrames(2);
      StudioStore.saveDesignDebounced(null, true);
    },
    handleColorChange(fill, index) {
      if(fill && !(fill instanceof Event) && this.state.colorPicker.item) {
        const prop = this.state.colorPicker.prop || 'fill';
        this.state.history.forEach(item => {
          if(item[prop] && item[prop]?.toString() === this.historyColors[this.state.colorPicker.index]?.toString()) {
            item[prop] = fill;
            if (item.render) {
              item.render();
            }
            if (item.updateUniforms) {
              item.updateUniforms();
            }
          }
        });
        this.historyColors[this.state.colorPicker.index] = fill;
        StudioStore.fullRedraw();
      }
      this.updateEffectValue();
    },
    render() {
      this.state.mouse.pos.x = weightedLerp(this.state.mouse.movePos.x, this.state.mouse.lastPos.x, 0);
      this.state.mouse.pos.y = weightedLerp(this.state.mouse.movePos.y, this.state.mouse.lastPos.y, 0);

      const delta = Math.sqrt(
        Math.pow(this.state.mouse.pos.x - this.state.mouse.movePos.x, 2) +
          Math.pow(this.state.mouse.pos.y - this.state.mouse.movePos.y, 2)
      );

      if (delta < 1 && this.state.tick) {
        cancelAnimationFrame(this.state.tick);
        this.state.tick = null;
        return;
      }

      if (this.state.tool !== 'selector') {
        const item = this.state.history.at(-1);

        if (this.state.drawing && delta > 1) {
          item.left = this.state.mouse.downPos.x / this.state.canvasWidth;
          item.top = this.state.mouse.downPos.y / this.state.canvasHeight;

          // Check if shift key is pressed
          const isShiftPressed = this.state.hotkeys.isPressed('shift');

          let width = roundXToNearestY(this.state.mouse.delta.x, 1);
          let height = roundXToNearestY(this.state.mouse.delta.y, 1);

          // If shift is pressed, make the shape a perfect square
          if (isShiftPressed) {
            const size = Math.max(Math.abs(width), Math.abs(height));
            width = width >= 0 ? size : -size;
            height = height >= 0 ? size : -size;
          }
          item.width = width;
          item.height = height;
          item.anchorPoint = 'topLeft';
          item.render();
        }
      }

      this.state.mouse.lastPos.x = this.state.mouse.pos.x;
      this.state.mouse.lastPos.y = this.state.mouse.pos.y;

      this.state.tick = requestAnimationFrame(this.render);
    },
    setUserZoom(val) {
      if(isNaN(val)) {
        if(val === 'fit') {
          if(!document.getElementById('drawContainer')) {
            throw new Error('Can\'t find draw container');
          }
          const drawContainerWidth = document.getElementById('drawContainer').getBoundingClientRect().width - (this.state.customCodeItemId ? 0 : (this.state.previewing ? 0 : 580));

          if (this.state.customCodeItemId) {
            const artBoardAspectRatio = drawContainerWidth / window.innerHeight;
            this.state.userZoom =
              this.state.currentSize.aspectRatio > artBoardAspectRatio
                ? drawContainerWidth / this.state.currentSize.dimensions[0]
                : window.innerHeight / this.state.currentSize.dimensions[1];
          } else {
            const artBoardAspectRatio = drawContainerWidth / (window.innerHeight - (this.state.previewing ? 0 : 120));
            this.state.userZoom =
              this.state.currentSize.aspectRatio > artBoardAspectRatio
                ? drawContainerWidth / this.state.currentSize.dimensions[0]
                : (window.innerHeight - (this.state.previewing ? 0 : 120)) / this.state.currentSize.dimensions[1];
          }
        } else if (val === 'in') {
          this.state.userZoom *= 1.25;
        } else if (val === 'out') {
          this.state.userZoom /= 1.25;
        }
      } else {
        this.state.userZoom = val;
      }
      this.state.panLeft = 0;
      this.state.panTop = 0;
    },
    applySliderTrack(val, max) {
      return {
        background: `linear-gradient(to right, #191919 0%, #191919 ${(val / max) * 100 - 0.01}%, #eaeaea ${
          (val / max) * 100
        }%, #eaeaea 100%)`,
      };
    },
    revertDownload() {
      this.state.export.downloading = false;
      this.state.export.resolution = 1;
      this.state.export.active = false;

      this.setScale(1, () => {
        this.state.panLeft = 0;
        this.state.panTop = 0;
        this.setUserZoom('fit');

        StudioStore.refreshPlanes(
          () => {
            this.playAnimation();
          },
          null,
          true
        );
      });
    },
    download() {
      this.state.panLeft = 0;
      this.state.panTop = 0;
      const animationLayers = this.state.history.filter(n => n.animating).map(n => n.local.id);
      this.state.history
        .filter(n => n.animating)
        .forEach(n => {
          n.toggleProp('animating');
        });
      // if(this.state.export.type === 'video') {
      //   StudioStore.addItem(new Effect({
      //     type: 'freeLogo'
      //   }));
      // }
      this.setScale(this.state.export.resolution, () => {
        StudioStore.refreshPlanes(
          () => {
            this.state.export.downloading = true;
            StudioStore.fullRedraw();
            StudioStore.renderNFrames(2, () => {
              let tags = {
                email: UserStore.email,
                uid: UserStore.id,
                hasPro: UserStore.hasProAcess,
                type: this.state.export.type,
                id: this.state.design.id,
              };

              if (this.state.export.type === 'video') {
                this.state.history.filter(n => animationLayers.includes(n.local.id)).forEach(n => (n.animating = true));
                this.exportVideo();
                tags = {
                  ...tags,
                  res: this.state.export.resolution,
                  format: this.state.export.videoType,
                  duration: this.state.export.duration,
                  fps: this.state.export.fps,
                };

                logsnagTrack({
                  channel: 'exports',
                  event: 'New export',
                  description: `email: ${UserStore.email}`,
                  icon: '💾',
                  user_id: UserStore.id,
                  notify: true,
                  tags: tags,
                });
              } else {
                this.exportImage();
                tags = {
                  ...tags,
                  res: this.state.export.resolution,
                  format: this.state.export.imageType,
                };
              }
            });
          },
          null,
          true
        );
      });
    },
    exportImage() {
      let pngdecoded = false;
      StudioStore.renderFrame();
      const link = document.createElement('a');
      link.download = `${this.state.designName}.${this.state.export.imageType}`;
      link.href = pngdecoded
        ? (window.webkitURL || window.URL).createObjectURL(pngdecoded)
        : this.state.curtain.canvas.toDataURL(`image/${this.state.export.imageType}`, this.state.export.quality);
      link.click();
      link.remove();
      if (pngdecoded) {
        (window.webkitURL || window.URL).revokeObjectURL(pngdecoded);
      }
      this.revertDownload();
    },
    async captureVideoFrames(fps, duration, mediaRecorder) {
      const totalFrames = fps * duration;
      const frameInterval = 1000 / fps;

      for (let frame = 0; frame < totalFrames; frame++) {
        const frameStart = performance.now();

        // Render the next frame (ensure this function is synced with your desired fps)
        StudioStore.renderFrame();

        // Update progress if needed
        StudioStore.state.export.progress = Math.floor(((frame + 1) / totalFrames) * 100);

        // Wait for the remainder of the frame interval
        const elapsed = performance.now() - frameStart;
        if (elapsed < frameInterval) {
          await new Promise(resolve => setTimeout(resolve, frameInterval - elapsed));
        }
      }
      mediaRecorder.stop();
    },

    exportVideo() {
      const handleStop = (chunks) => {
        const blob = new Blob(chunks);
        if (StudioStore.state.export.videoType === 'webm') {
          this.downloadVideoFile(blob);
        } else {
          this.convertAndDownloadVideo(blob);
        }
      };

      let chunks = [];
      StudioStore.state.downloading = true;
      StudioStore.state.videoStream = this.state.curtain.canvas.captureStream(this.state.export.fps);

      const startTime = performance.now();
      // Reset animation states
      StudioStore.state.curtain.planes.forEach(plane => {
        plane.userData.createdAt = startTime;
      });
      
      // Reset appear effects
      StudioStore.state.history
        .filter(n => n.states && n.states.appear.length)
        .forEach(item => {
          item.local.createdAt = startTime;
          item.states.appear.forEach(effect => {
            effect.resetState();
          });
        });

      const mimeType = getSupportedMimeType();
      if (!mimeType) {
        window.alert('Video recording is not supported in this browser. Please try Chrome, Firefox, or Edge.');
        this.revertDownload();
        return;
      }

      const mediaRecorder = new MediaRecorder(StudioStore.state.videoStream, {
        mimeType: mimeType,
        audioBitsPerSecond: 0,
        videoBitsPerSecond: Math.max(2500000 * this.state.export.scale),
      });

      mediaRecorder.ondataavailable = (evt) => chunks.push(evt.data);
      mediaRecorder.onstop = () => handleStop(chunks);

      mediaRecorder.start(50);
      cancelAnimationFrame(StudioStore.state.curtainRafId);
      
      // Start the frame capture loop
      this.captureVideoFrames(
        this.state.export.fps,
        this.state.export.duration,
        mediaRecorder
      );
    },
    downloadVideoFile(blob) {
      const recording_url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style = "display: none;";
      a.href = recording_url;
      a.download = "video." + this.state.export.videoType;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        URL.revokeObjectURL(recording_url);
        document.body.removeChild(a);
      }, 0);

      this.revertDownload();
    },
    async convertAndDownloadVideo(blob) {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const arrayBuffer = await blob.arrayBuffer();
        
        const response = await axios.post(
          'https://us-central1-unicorn-studio.cloudfunctions.net/convertWebmToType',
          arrayBuffer,
          {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/octet-stream'
            },
            responseType: 'blob'
          }
        );

        const responseBlob = response.data;
        const text = await responseBlob.text();
        const data = JSON.parse(text);

        if (data.downloadUrl) {
          const videoResponse = await fetch(data.downloadUrl);
          const videoBlob = await videoResponse.blob();
          const blobUrl = URL.createObjectURL(videoBlob);
          
          const a = document.createElement('a');
          a.style = 'display: none';
          a.href = blobUrl;
          a.download = 'video.mp4';
          
          document.body.appendChild(a);
          a.click();
          
          URL.revokeObjectURL(blobUrl);
          a.remove();
          this.revertDownload();
        } else {
          throw new Error('Download URL not received');
        }
      } catch (err) {
        console.error(err);
        this.state.export.active = false;
        window.alert('Failed to process video: ' + err);
      }
    },
    fastRedraw() {
      if(!this.animatingEffects.length) {
        StudioStore.renderFrame();
      }
      this.state.drawUIUpdater++;
    },
    updatePlaceholderImage() {
      const temp = document.createElement('canvas');
      const tempctx = temp.getContext('2d');
      this.fastRedraw();
      temp.width = this.canvasWidth / 30;
      temp.height = this.canvasHeight / 30;
      tempctx.drawImage(this.state.curtain.canvas, 0, 0, this.canvasWidth / 30, this.canvasHeight / 30);
    },
    getFillStyle(fill) {
      return getFillStyle(fill);
    },
    nudgeItem(direction, amount) {
      if (this.state.selectedItems.length) {
        this.state.selectedItems.forEach(item => {
          let yAxis = direction === 'up' || direction === 'down';
          let prop = yAxis ? 'y' : 'x';
          const increment = amount / StudioStore.state.canvasWidth;
          if (item.pos) {
            item.pos[prop] = item.pos[prop] + increment;
          }
          if(item.isElement && prop === 'y') {
            item.top = item.top + increment;
          }
          if(item.isElement && prop === 'x') {
            item.left = item.left + increment;
          }
        });
        this.updateEffectValue();
      }
    },
    deleteHistoryItem(item) {
      StudioStore.removeItem(item);
    },
    updateUndoState() {
      this.state.history.forEach(item => {
        if (item.package) {
          this.state.initialChangeStates.push({
            state: item.package(true),
            id: item.local.id,
          });
        }
      });
      this.handleBatchChanges();
    },
    updateHistoryState() {
      this.state.selectedItems.forEach(item => {
        if (item.render) {
          item.render();
          item.local.stateEffectProps = {};
        }
      });
      this.fastRedraw();

      this.undoStateUpdater.fire();
      StudioStore.saveDesignDebounced(null, true);
    },
    changePaletteFill(fill, index) {
      // bug
      this.state.colorPicker = {
        item: {
          fill: fill,
        },
        index: index,
        colorsInUse: true,
      };
    },
    saveSnapshot() {
      StudioStore.renderFrame();
      const history = packageVersion(StudioStore.state.history);
      const src = StudioStore.state.curtain.canvas.toDataURL();
      this.createAndSaveVersion(src, history);
    },
    saveRemixSnapshot(remix) {
      const history = packageVersion(remix.history);
      this.createAndSaveVersion(remix.src, history, () => {});
    },
    createAndSaveVersion(src, history, onSaved) {
      compressThumbnail(src, 400, thumb => {
        const version = {
          creatorId: UserStore.id,
          designId: this.state.design.id,
          history: history,
          createdAt: new Date(),
          thumbnail: thumb,
        };

        FirebaseStore.createVersion(version)
          .then(resp => {
            version.createdAt = formatDate(new Date());
            this.versions.push(version);
            if (onSaved) {
              onSaved();
            }
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    viewVersions() {
      if (!this.versions.length) {
        FirebaseStore.getVersions(this.state.design.id)
          .then(docs => {
            this.versions = docs.docs
              .map(n => n.data())
              .map(n => {
                n.createdAt = formatDate(n.createdAt.toDate());
                return n;
              });
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    selectVersion(version) {
      this.state.loading = true;
      this.clearAll();
      this.state.history = unpackageHistory(version.history);
      StudioStore.handleItemPlanes(() => {
        this.setScale(this.state.scale, () => {
          this.state.loading = false;
          this.playAnimation();
        });
      });
    },
    updateEffectValue(ev) {
      if (ev && !(ev instanceof Event)) {
        StudioStore.setSelectedItem(ev);
      }
      StudioStore.state.selectedItems.forEach(item => {
        if (item.isElement) {
          item.getChildEffectItems().forEach(item => {
            item.updateUniforms();
          });
        } else if (item.updateUniforms) {
          item.updateUniforms();
        }
      });
      this.updateHistoryState();
    },
    editItem(item) {
      this.state.tool = 'selector';
      if (StudioStore.state.hotkeys.isPressed('cmd')) {
        StudioStore.state.selectedItems = [item, ...StudioStore.state.selectedItems];
      } else {
        StudioStore.setSelectedItem(item);
      }
      this.state.drawUIUpdater++;
    },
    playAnimation() {
      if (this.state.animatingEffects.length) {
        cancelAnimationFrame(StudioStore.state.curtainRafId);
        StudioStore.renderCurtainFPS(60);
      }
    },
    openPromptModal(message, callback) {
      this.promptModal.open = true;
      this.promptModal.message = message || 'Are you sure?';
      this.promptModal.callback = callback;
    },
    openImageBrowser(id) {
      this.state.imagesBrowser.open = true;

      if (id) {
        this.state.imagesBrowser.replaceId = id;
      }
    },

    setScale(scale, callback) {
      StudioStore.setScale(scale, callback);
    },

    fitCanvas() {
      this.$nextTick(() => {
        this.handleWindowResize();
      });
    },
    addItemEffect(item) {
      this.state.browsingEffects = true;
      StudioStore.setSelectedItem(item);
    },
    editItemCode(item) {
      this.state.customCodeItemId = item.local.id;
      this.fitCanvas();
    },
    handleBatchChanges() {
      if (this.state.initialChangeStates.length) {
        const command = new UpdateCommand(this.state.initialChangeStates);
        StudioStore.performAction(command);
        this.state.initialChangeStates.length = 0;
      }
    },
    resetBreakpoints() {
      StudioStore.state.size = 'Desktop';
      StudioStore.state.history.forEach(item => {
        if (item.breakpoints) {
          item.breakpoints.forEach(bp => {
            if (bp.name === 'Desktop') {
              for (let prop in bp.props) {
                if(bp.props[prop] !== undefined) {
                  item[prop] = bp.props[prop];
                }
              }
            }
            bp.props = {};
          });
        }
      });
      this.updateHistoryState();
    },
  },
};
</script>

<template>
  <div class="main-container" :style="{ pointerEvents: state.export.downloading ? 'none' : 'auto' }">
    <div v-if="loading || state.loading" class="loading-bar"></div>
    <TopBar
      v-if="showTopBar"
      @set-scale="setScale"
      @set-zoom="setUserZoom"
      @add-image="openImageBrowser"
      @reset-breakpoints="resetBreakpoints"
      @resize-canvas="handleCanvasResize"
    />

    <div class="artboard-container flex justify-between">
      <ColorBrowser
        v-if="state.colorPicker.item"
        :color="state.colorPicker"
        :historyColors="historyColors"
        @update="handleColorChange($event, state.colorPicker.item && state.colorPicker.item[state.colorPicker.prop || 'fill'])"
        @close="state.colorPicker.item = null"
      ></ColorBrowser>
      <div class="left-rail" v-if="showLeftRail">
        <HistoryList
          :loading="state.loading"
          :editing="state.selectedItems[0]"
          :historyColors="state.historyColors"
          :versions="versions"
          @update-history-state="updateHistoryState"
          @clear="clearAll"
          @edit-item="editItem"
          @delete-item="deleteHistoryItem"
          @update-value="updateEffectValue"
          @add-effect="addItemEffect"
          @edit-code="editItemCode"
          @save-snapshot="saveSnapshot"
          @select-version="selectVersion"
          @view-versions="viewVersions"
          @replace-image="openImageBrowser"
        >
        </HistoryList>
      </div>
      <div class="canvas-container" id="artboard" :class="state.customCodeItemId ? 'draw-container-editing-code' : ''">
        <div
          id="drawContainer"
          class="draw-container"
          :class="state.customCodeItemId ? '' : ''"
          :style="{ cursor: state.tool === 'shape' ? 'crosshair' : 'auto' }"
        >
          <CanvasUI
            v-if="showCanvasUI"
            :aspectRatio="currentSize.aspectRatio"
            :updater="state.drawUIUpdater"
            :cW="canvasWidth"
            :cH="canvasHeight"
            @update="updateHistoryState"
            @render="updateEffectValue"
            @add-effect="addItemEffect"
            @delete-item="deleteHistoryItem"
          ></CanvasUI>
          <Artboard :zoomStyle="zoomStyle" :outputStyle="outputStyle" :canvasPlaneStyle="canvasPlaneStyle" />
        </div>
      </div>

      <div v-if="showHistoryContainer" class="history-container mr-1" id="controls-form">
        <MultiSelectControls v-if="state.selectedItems.length > 1" @change="updateHistoryState"> </MultiSelectControls>
        <ShapeControls
          v-else-if="editingItem && editingItem.layerType === 'shape'"
          class="control-section-wrapper"
          @change="updateEffectValue"
          @input="updateEffectValue"
          @edit-fill="state.colorPicker = $event"
        ></ShapeControls>
        <TextControls
          v-else-if="editingItem && editingItem.layerType === 'text'"
          class="control-section-wrapper"
          :fontFamilies="fontFamilies"
          :fontData="fontData"
          @edit-fill="state.colorPicker = $event"
          @change="updateEffectValue"
          @update="updateEffectValue"
          @input="updateEffectValue"
        ></TextControls>
        <ImageControls
          v-else-if="editingItem && editingItem.layerType === 'image'"
          class="control-section-wrapper"
          @edit-fill="state.colorPicker = $event"
          @change="updateEffectValue"
          @input="updateEffectValue"
          @replace-image="openImageBrowser($event)"
        ></ImageControls>
        <EffectControls
          v-else-if="editingItem && editingItem.layerType === 'effect'"
          :key="editingItem.local.id"
          class="control-section-wrapper"
          @update-value="updateEffectValue"
          @edit-fill="state.colorPicker = $event"
          @fit="fitCanvas"
          @replace-image="openImageBrowser($event)"
        ></EffectControls>
        <div v-if="editingItem && editingItem.effects && editingItem.effects.length" class="flex column-reverse">
          <EffectControls
            v-for="effect in state.history.filter(n => editingItem.effects.includes(n.parentLayer))"
            :childEffect="effect"
            :key="effect.local.id"
            @update-value="updateEffectValue"
            @edit-fill="state.colorPicker = $event"
            @delete-item="deleteHistoryItem($event)"
          ></EffectControls>
        </div>
        <div
          v-if="state.history.length && !editingItem"
          class="parameters parameters__column mt-1 control-section-wrapper effect-properties"
        >
          <label class="parameter-label parameter-label__margin-bottom">Project colors</label>
          <div class="doc-colors">
            <ColorInput
              v-for="(color, index) in historyColors"
              :fill="color"
              :key="'color_' + index"
              @change="fill => handleColorChange(fill)"
              @click-swatch="changePaletteFill(color, index)"
            ></ColorInput>
          </div>
        </div>
      </div>

      <div
        v-else-if="!state.previewing && !state.export.active"
        class="history-container history-container-editing-code"
      >
        <EffectControls
          :key="state.customCodeItemId"
          @update-value="updateEffectValue"
          @edit-fill="state.colorPicker = $event"
          @fit="fitCanvas"
          @replace-image="openImageBrowser($event)"
        ></EffectControls>
      </div>

      <FontsBrowser v-if="state.browsingFonts" />

      <template v-if="state.openStateEffect">
        <StateEffectAppear v-if="state.openStateEffect.type === 'appear'" @update="updateEffectValue" />
        <StateEffectHover v-if="state.openStateEffect.type === 'hover'" @update="updateEffectValue" />
        <StateEffectScroll v-else-if="state.openStateEffect.type === 'scroll'" @update="updateEffectValue" />
      </template>

      <Timeline 
        v-if="showTimeline" 
        @update:event="updateHistoryState"
      />

      <Editor v-if="state.customCodeItemId" @fit="fitCanvas"/>

      <div v-if="state.promptModal.open" class="modal modal__prompt modal__pop-in">
        <h3 class="h3">{{ state.promptModal.title }}</h3>
        <p class="p" v-html="state.promptModal.message"></p>
        <p class="dialog dialog__warning" v-if="state.promptModal.breakingChange">
          <p class="p p__bold mt-0 mb-1 font-weight-medium">Is your scene active on a live site? Read this</p>
          Publishing without updating the library version will break your embed. You should consider duplicating this project so you can update the project ID and the library version together.
          <br><br>
          For the Framer component, you can unlink the component and replace 1.3.2 with 1.4.1 in the cdn link.
          <br><br>
          Apologies for the inconvenience.
        </p>
        <div class="button-group button-group__end">
          <Button
            @click="
              state.promptModal.callback(false);
              state.promptModal.open = false;
            "
            class="secondary"
            >Cancel</Button
          >
          <Button
            @click="
              state.promptModal.callback(true);
              state.promptModal.open = false;
            "
            class="primary"
            >{{ state.promptModal.confirmText }}</Button
          >
        </div>
      </div>

      <EffectBrowser v-if="state.browsingEffects" @fit-canvas="fitCanvas"></EffectBrowser>

      <ImagesBrowser v-if="state.imagesBrowser.open" @update="updateEffectValue"></ImagesBrowser>
      <SDFShapeBrowser v-if="state.sdfShapeBrowser"></SDFShapeBrowser>

      <ExportWindow v-if="state.export.active" @download="download" currentSize />

      <ProModal v-if="state.signUpForPro" />

      <a v-if="!state.previewing" class="discord-bubble" href="https://discord.gg/yCvM5qeTbv" target="_blank">
        <Icon icon="discord" size="24" color="white" />
      </a>
      <a v-if="!state.previewing" class="help-bubble" href="https://unicornstudio.notion.site/Unicorn-Studio-9a47f6a0e4c74d29b638d72c8a504fbb?pvs=73" target="_blank">
        <Icon icon="help" size="24" color="white" />
      </a>

      <div v-if="state.webglError" class="curtain-error dialog dialog__error">
        Error: lost WebGL context. If this message persists, please refresh the page.
      </div>
      <svg
        style="display: none"
        width="401"
        height="336"
        viewBox="0 0 401 336"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M212 157.5V233.5L90.5 334L129 195L2 217.5L136.5 89.5L62 73.5L172.5 1.5L212 129C292.333 103.833 440.3 63.7 389.5 104.5C338.7 145.3 250 156.833 212 157.5Z"
          stroke="black"
        />
      </svg>
      <svg style="display: none" width="302" height="303" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M295.994 108.794c11.849-10.739 3.711-30.447-12.217-29.624L238.8 81.474c-10.045.514-18.306-7.797-17.794-17.857l2.296-45.135c.82-15.985-18.839-24.152-29.519-12.282l-30.197 33.553c-6.723 7.489-18.429 7.489-25.173 0L108.237 6.22c-10.7-11.89-30.34-3.723-29.52 12.282l2.296 45.136c.513 10.08-7.769 18.37-17.793 17.856L18.243 79.19C2.315 78.367-5.823 98.076 6.005 108.814l33.435 30.283c7.462 6.748 7.462 18.494 0 25.263L6.005 194.642c-11.849 10.739-3.71 30.447 12.238 29.624l44.977-2.304c10.045-.514 18.306 7.797 17.793 17.857l-2.296 45.135c-.82 15.985 18.84 24.152 29.52 12.282l30.176-33.553c6.744-7.489 18.45-7.489 25.173 0l30.176 33.553c10.701 11.891 30.34 3.724 29.52-12.282l-2.296-45.135c-.513-10.081 7.769-18.371 17.794-17.857l44.976 2.304c15.928.823 24.067-18.906 12.218-29.624l-33.435-30.282c-7.462-6.748-7.462-18.495 0-25.263l33.455-30.303z"
          fill="#F4B510"
        />
      </svg>
      <svg style="display: none" width="237" height="560" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          id="importPath"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M60.285.23c33.562 7.124 42.48 44.29 43.909 63.359 4.023 9.374 9.456 28.98 14.115 59.702 11.484-18.294 54.441-72.095 75.172-37.803 10.849 17.946-31.591 34.29-73.715 47.982 1.85 13.699 3.532 29.354 4.887 47.042 12.161-15.992 36.801-38.6 66.775-28.852 17.036 5.541-32.578 45.991-65.715 44.236.951 15.474 1.652 32.335 2.012 50.626 18.744-27.685 73.624-92.099 100.768-47.631 14.84 24.313-42.908 46.464-100.533 65.045.026 3.614.04 7.279.04 10.995 0 11.499-.13 22.899-.374 34.156 16.668-24.176 56.102-65.146 104.867-49.196 26.647 8.716-74.047 75.271-105.343 66.871a1766.041 1766.041 0 01-2.318 49.841c33.828-57.931 103.676-64.641 98.333-39.263-11.227 53.332-72.812 65.432-100.187 66.531a1853.95 1853.95 0 01-5.041 55.68c45.133-39.091 103.927-76.249 98.422-50.102-11.234 53.365-72.888 65.447-100.237 66.533-4.307 37.369-8.914 66.682-12.194 83.772l-7.856-1.508c3.523-18.361 8.617-51.122 13.2-92.947-71.056-.51-126.413-59.565-104.368-77.408 45.752-37.031 91.209 26.712 106.951 52.208a1850.896 1850.896 0 005.398-70.913c-58.41-19.227-129.898-56.611-103.349-65.295 48.156-15.751 87.212 24.005 104.232 48.283a1678.523 1678.523 0 001.742-57.863c-45.809-14.78-102.27-43.818-81.412-50.543 37.64-12.135 68.177 18.443 81.522 37.178a1416.584 1416.584 0 00-.381-39.774C62.077 222.609 4.544 200.491 19.36 176.218c26.626-43.619 79.941 17.529 99.656 46.009-.882-22.765-2.303-42.949-4.045-60.66-27.228-1.025-89.21-13.021-100.477-66.542-5.61-26.65 61.674.927 98.367 47.444a667.544 667.544 0 00-1.82-13.638c-1.327-9.204-2.722-17.408-4.125-24.646-45.788-2.3-83.258-37.894-65.947-43.525 25.852-8.407 47.736 7.254 61.068 21.98-1.02-3.815-2.004-7.093-2.927-9.848C71.673 42.554 41.986-3.654 60.285.23z"
          fill="#E7FEDE"
        />
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-container {
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  line-height: 1.4;
  background-color: var(--artboard-color);
}

.artboard-container {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--artboard-color);
}

.canvas-container,
.viewer-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--artboard-color);
  transition: background-color 0.3s ease;
  z-index: 0;
  canvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.canvas-container.draw-container-editing-code {
  position: relative;
  width: calc(100% - 98rem);
  left: 70rem;
}

.menu-bar {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: calc(100%);
  background-color: var(--bg-color);
  padding: 1.2rem 1.5rem;
  align-items: center;
}

.left-rail {
  position: relative;
  padding-top: 5.4rem;
  max-width: var(--sidebar-width);
  width: 100%;
  z-index: 999;
}

.draw-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  overscroll-behavior-x: none;
}

.history-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 30rem;
  padding-top: 5.4rem;
  max-width: var(--sidebar-width);
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 99;

  &.history-container-editing-code {
    padding-top: 0;
  }
}

.history-container-header {
  padding: 1.25rem 1.5rem;
  background-color: var(--bg-color);
}

.hide-history-item,
.lock-history-item,
.remove-history-item {
  color: var(--font-secondary-color);
  &:hover {
    color: var(--font-color);
  }
}

.hide-history-item {
  &.item__hidden {
    visibility: visible;
  }
}

.lock-history-item {
  &.item__locked {
    display: flex;
    color: var(--font-color);
  }
}

.tally-button {
  position: fixed;
  bottom: 2rem;
  right: 1.5rem;
  z-index: 9;
  background-color: var(--accent-color);
  box-shadow: 0px 0.2px 0.5px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: var(--primary-color);
  }
}

.discord-bubble,
.help-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  color: var(--font-secondary-color);
  background-color: var(--accent-color);
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.25);
  transition: color 0.25s ease;

  &:hover {
    color: var(--font-color);
  }
}

.help-bubble {
  right: 7rem;
}
</style>
