<script>
import Icon from './Icon.vue';
import Input from './Input.vue';
import { StudioStore } from '../stores/StudioStore';
import { Effect } from '../scripts/layerTypes/Effect.js';
import { EFFECTS } from '../scripts/Shaders.js';
import { generateUUID } from '../scripts/Helpers.js';

const groupings = [
  {
    label: 'Filters',
    items: [
      {
        id: 'coloration',
        name: 'Adjust',
        description:
          'Modifies image attributes such as hue, saturation, exposure, contrast, tint, sharpness, and gamma for detailed color manipulation.',
      },
      {
        id: 'duotone',
        name: 'Duotone',
        description: 'Applies a two-tone color effect, transforming the image with a dual color filter.',
      },
      {
        id: 'hologram',
        name: 'Hologram',
        description: 'Applies a holographic effect, imparting a shimmering multi-color sheen to the image.',
      },
      {
        id: 'gradientMap',
        name: 'Gradient map',
        description: "Maps a gradient to the texture's brightness, cycling through the colors from dark to light.",
      },
      {
        id: 'vignette',
        name: 'Vignette',
        description: 'Adds a vignette effect around the edges of the scene.',
      },
    ],
  },
  {
    label: 'Distort',
    items: [
      // {
      //   id: 'transform',
      //   name: 'Transform',
      //   description: 'Applies a transformation to the scene.',
      // },
      {
        id: 'mouse',
        name: 'Mouse trail',
        description: 'Adds distortion and other effects to the scene with mouse movement.',
      },
      {
        id: 'waterRipple',
        name: 'Mouse ripple',
        description: 'Adds a trail of water ripples that follow the mouse.',
      },
      {
        id: 'liquify',
        name: 'Liquify',
        description: 'Creates a liquid-like distortion, giving the image a fluid and wavy appearance.',
      },
      {
        id: 'bulge',
        name: '3D bulge/pinch',
        description: 'Bulges/pinches the vertices of the texture, creating a 3-dimensional distortion effect',
      },
      {
        id: 'fbm',
        name: 'FBM',
        description: 'Emulates organic distortion through Fractal Brownian Motion, replicating natural textures.',
      },
      {
        id: 'flowField',
        name: 'Flow field',
        description: 'Produces a vector field based on a noise algorithm, resulting in a dynamic distortion effect.',
      },
      {
        id: 'noise',
        name: 'Noise',
        description: 'Incorporates a random noise distortion, warping the image with an organic ungulation.',
      },
      {
        id: 'noiseField',
        name: 'Waves',
        description: 'Creates an organic wave distortion, forming a fluid and undulating image effect.',
      },
      {
        id: 'blinds',
        name: 'Blinds',
        description: 'Slices the image into offset segments, resembling the pattern of window blinds.',
      },
      {
        id: 'ripple',
        name: 'Ripple',
        description: 'Imposes a ripple effect on the image, giving it a water-like surface impression.',
      },
      {
        id: 'sine',
        name: 'Sine waves',
        description: 'Generates a sinusoidal wave distortion, creating a rhythmic oscillating effect.',
      },
      {
        id: 'sphere',
        name: 'Lens distort',
        description: 'Simulates a fisheye lens distortion, providing a spherical perspective to the image.',
      },
      {
        id: 'swirl',
        name: 'Swirl',
        description: 'Introduces a swirling distortion, twirling the image around a center point.',
      },
      {
        id: 'scan',
        name: 'Stretch',
        description: 'Executes a directional stretch effect, warping the scene along an axis.',
      },
      {
        id: 'extend',
        name: 'Extend',
        description: 'Executes a directional stretch effect, repeating the pixel on an axis at a point.',
      },
      {
        id: 'voronoi',
        name: 'Shatter',
        description:
          'Implements a Voronoi fragmentation, splitting the image into irregular triangles for a shattered effect.',
      },
      {
        id: 'projection',
        name: 'Projection',
        description: 'Creates an environmental projection effect using the underlying texture',
      },
      {
        id: 'polar',
        name: 'Polar',
        description: 'Wraps the texture around a point',
      },
    ],
  },
  {
    label: 'Blur',
    items: [
      {
        id: 'blur',
        name: 'Blur',
        description:
          'Applies a directional Gaussian/Box blur, allowing for tilt shift adjustments to simulate depth of field.',
      },
      {
        id: 'progressiveBlur',
        name: 'Progressive blur',
        description: 'Blurs the scene progressively in one direction.',
      },
      {
        id: 'bokeh',
        name: 'Bokeh',
        description:
          'Generates a cinematic bokeh effect, mimicking a shallow depth of field as captured by a camera lens.',
      },
      {
        id: 'diffuse',
        name: 'Diffuse',
        description: 'Imposes a grainy blur effect, providing a film-like texture to the scene.',
      },
      {
        id: 'zoomBlur',
        name: 'Zoom blur',
        description: 'Applies a zoom blur effect.',
      },
      {
        id: 'noiseBlur',
        name: 'Noise Blur',
        description: 'Creates an ethereal wispy blur effect.',
      },
    ],
  },
  {
    label: 'Light',
    items: [
      {
        id: 'beam',
        name: 'Beam',
        description: 'Adds a light beam effect on top of your scene.',
      },
      {
        id: 'spotlight',
        name: 'Point light',
        description: 'Adds a point light to your scene that casts tinted highlights and shadows',
      },
      {
        id: 'bloom',
        name: 'Bloom',
        description: 'Simulates a bloom effect, enhancing the luminosity of bright areas in the image.',
      },
      {
        id: 'godrays',
        name: 'God rays',
        description:
          'Creates light rays emanating from bright areas, adding a dramatic 3D lighting effect to the scene.',
      },
      {
        id: 'twodlight',
        name: '2D Light',
        description: 'Adds a 2D light source that casts shadows off objects in your scene.',
      },
      {
        id: 'caustics',
        name: 'Water caustics',
        description: 'Adds a caustic lighting effect on the image, giving it a water-like surface impression.',
      },
    ],
  },
  {
    label: 'Stylize',
    items: [
      {
        id: 'dither',
        name: 'Dither',
        description:
          'Applies a dithering effect with options for random, bayer, or blue noise, providing a pixelated visual style.',
      },
      {
        id: 'halftone',
        name: 'Halftone',
        description: 'Creates a halftone effect in CMYK, RGB, or monotone, simulating traditional print patterns.',
      },
      {
        id: 'grain',
        name: 'Grain',
        description: 'Adds a grain effect to the image with various blend modes, giving a textured film-like quality.',
      },
      {
        id: 'chromab',
        name: 'Chromatic abbe...',
        description:
          'Introduces chromatic aberration, creating a visual distortion by separating the red and blue channels.',
      },
      {
        id: 'ascii',
        name: 'Glyph dither',
        description: 'Transforms the image into ASCII art.',
      },
      {
        id: 'pixelate',
        name: 'Pixelate',
        description: 'Transforms the image into a pixelated representation, replicating a retro digital art style.',
      },
      {
        id: 'retro_screen',
        name: 'Retro screen',
        description: 'Applies various retro screen pixelation / CRT effects',
      },
      {
        id: 'texturize',
        name: 'Glitch',
        description: 'Introduces a randomized glitch effect, providing a digital malfunction aesthetic.',
      },
      {
        id: 'guilloche',
        name: 'Guilloche',
        description: 'Creates a guilloche effect, similar to a line based halftone pattern.',
      },
    ],
  },
  {
    label: 'Misc',
    items: [
      {
        id: 'sdf_shape',
        name: '3D SDF Shape',
        description: 'Adds a 3D distance field shape to the scene',
      },
      {
        id: 'video',
        name: 'Video',
        description: 'Adds a video to the scene',
      },
      {
        id: 'gradientFill',
        name: 'Fill / gradient',
        description: 'Adds a fullscreen gradient.',
      },
      // {
      //   id: 'landscape',
      //   name: 'Landscape',
      //   description: 'Creates a flyover landscape scene.'
      // },
      {
        id: 'replicate',
        name: 'Replicate',
        description: 'Repeats the layer in a bidirectional manner, creating a patterned effect.',
        limitTo: 'layer',
      },
      {
        id: 'reflectiveSurface',
        name: 'Reflective surface',
        description: 'Reflects the scene onto a simulated surface',
      },
      {
        id: 'stars',
        name: 'Sparkle',
        description: 'Adds stars/sparkle to your scene.',
      },
      {
        id: 'wisps',
        name: 'Wisps',
        description: 'Creates animated starry wisp particles.',
      },
      {
        id: 'pattern',
        name: 'Pattern',
        description: 'Adds a an assortment of repeating patterns.',
      },
      {
        id: 'mirror',
        name: 'Mirror',
        description:
          'Applies a kaleidoscope-like mirror effect, reflecting and repeating the image in a symmetrical pattern.',
      },
      {
        id: 'fxaa',
        name: 'Anti-alias',
        description: 'Fast approximate anti-alias',
      },
    ],
  },
  {
    label: 'Custom',
    items: [
      {
        id: 'custom',
        name: 'Custom',
        description: 'Allows for the creation of custom shader effects through user-defined code.',
      },
    ],
  },
];

export default {
  components: {
    Icon,
    Input,
  },
  props: ['currentSize'],
  data() {
    return {
      state: StudioStore.state,
      effects: EFFECTS,
      groupings,
      searchQuery: '',
      activeGroup: null,
    };
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.$nextTick(() => {
      const effectsWrapper = this.$el.querySelector('.effects');
      effectsWrapper.addEventListener('scroll', this.handleScroll);
      this.handleScroll({ target: effectsWrapper });
    });
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    const effectsWrapper = this.$el.querySelector('.effects');
    effectsWrapper.removeEventListener('scroll', this.handleScroll);
    StudioStore.state.swapEffectItem = null;
  },
  methods: {
    handleScroll(event) {
      const scrollTop = event.target.scrollTop;
      let currentGroup = null;

      this.filteredGroupings.forEach(group => {
        const groupElement = this.$el.querySelector(`.effect-group-label[id="${group.label}"]`);
        if (groupElement && groupElement.offsetTop - 5 <= scrollTop) {
          currentGroup = group.label;
        }
      });

      this.activeGroup = currentGroup;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.state.browsingEffects = false;
      }
    },
    handleClickEffect(type) {
      if (StudioStore.state.swapEffectItem) {
        this.swapEffect(type);
      } else {
        this.activateEffect(type);
      }
    },
    swapEffect(type) {
      let item = StudioStore.state.swapEffectItem;
      item.type = type;
      item.states.appear = [];
      item.states.scroll = [];
      const newItem = new Effect(item);
      StudioStore.replaceItem(newItem, item);
      this.state.browsingEffects = false;
    },
    activateEffect(type) {
      const effect = new Effect({
        type: type,
      });

      if (StudioStore.getSelectedItem()) {
        effect.parentLayer = generateUUID();
        StudioStore.getSelectedItem().effects.push(effect.parentLayer);
      }

      StudioStore.addItem(effect);

      if (!StudioStore.getSelectedItem()) {
        StudioStore.setSelectedItem(effect);
      }

      if (type === 'custom') {
        this.$nextTick(() => {
          StudioStore.state.customCodeItemId = effect.local.id;
        });
      }

      this.state.browsingEffects = false;
    },
    handleEffectPreview(id) {
      this.previewEffect.getPlanes().forEach(plane => plane.remove());
      this.previewEffect.type = id;
      StudioStore.refreshPlanes(() => {}, this.previewEffect);
    },
    handleAnchorClick(group) {
      const element = document.querySelector(`.effects .effect-group-label[id="${group}"]`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
  },
  computed: {
    userEffects() {
      const userEffects = {};
      const keys = Object.keys(EFFECTS);
      keys.forEach(key => {
        if (!EFFECTS[key].params.hidden) {
          userEffects[key] = EFFECTS[key];
        }
      });
      return userEffects;
    },
    addingChildEffect() {
      return StudioStore.getSelectedItem();
    },
    filteredGroupings() {
      if (!this.searchQuery) {
        return this.groupings;
      }

      return this.groupings
        .map(group => ({
          ...group,
          items: group.items.filter(
            item =>
              item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
              item.description.toLowerCase().includes(this.searchQuery.toLowerCase())
          ),
        }))
        .filter(group => group.items.length > 0);
    },
  },
};
</script>

<template>
  <div v-if="state.browsingEffects" id="effect-browser" class="modal modal__effects modal__pop-in">
    <div class="flex effects-wrapper">
      <div class="effect-groups mr-2">
        <a
          v-for="group in filteredGroupings"
          @click="handleAnchorClick(group.label)"
          class="context-menu-link"
          :class="{ 'context-menu-link__active': group.label === activeGroup }"
          href="javascript:void(0)"
          :key="group.label"
          >{{ group.label }}</a
        >
      </div>
      <div class="effects">
        <div class="search w-100">
          <Input class="search-field w-100" v-model="searchQuery" placeholder="Search effects" />
        </div>
        <span v-for="group in filteredGroupings" :key="group.label">
          <div class="effect-group-label mb-1" :id="group.label">{{ group.label }}</div>
          <div class="group-wrapper">
            <div
              class="effect-container"
              v-for="effect in group.items"
              @click="handleClickEffect(effect.id)"
              :class="{
                'effect-container__lock': addingChildEffect && effect.limitTo === 'scene',
                'effect-container__lock': !addingChildEffect && effect.limitTo === 'layer',
              }"
              :key="effect.id"
            >
              <Icon v-if="effects[effect.id].animation" icon="play" size="18" class="animate-icon" tooltip="Animates" />
              <img :src="'/images/' + effect.id + '.png'" />
              <div class="flex justify-between">
                {{ effect.name }}
                <Icon icon="info" class="gray" :tooltip="effect.description" />
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal__effects {
  width: 75rem;
  height: 65rem;
  top: 5.9rem;
  right: unset;
  left: 28.5rem;
  padding: 0 1.5rem;
  border-radius: 0.8rem;
  z-index: 99999;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2rem;
    width: 100%;
    background: linear-gradient(to top, var(--bg-color), var(--bg-color-no-alpha));
  }
}

.search {
  margin: 1.4rem 0rem 0.4rem;
  padding: 0 var(--unit1);
}

.search-field {
  font-size: 1.4rem;
  padding: 1rem 1.2rem;
  color: var(--font-primary-color);
}

.effects-wrapper {
  height: 100%;
}

.animate-icon {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #ffffff75;
}

.effects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: auto;
  padding-bottom: 45rem;
}

.effect-groups {
  min-width: 15rem;
  margin-top: 1.5rem;
}

.effect-group-label {
  color: var(--font-secondary-color);
  padding-top: var(--unit4);
  margin-left: var(--unit1);
  text-transform: uppercase;
}

.group-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.context-menu-link {
  color: var(--font-secondary-color);

  &:hover {
    color: var(--font-primary-color);
  }

  &.context-menu-link__active {
    color: var(--font-primary-color);
    background-color: var(--accent-color);
  }
}

.effect-container {
  position: relative;
  width: calc(25% - 1rem);
  padding: 0.5rem;
  margin: 0.5rem;
  background-color: var(--accent-color);
  border-radius: 0.4rem;

  &.effect-container__lock {
    opacity: 0.6;
    pointer-events: none;
  }

  &:empty {
    display: none;
  }

  &.effect-container__active {
    background-color: var(--primary-active-bg);
    color: var(--selected-fill-color);
    box-shadow: inset 0 0 0 0.1rem var(--primary-color);
  }
  &.effect-container__active:hover {
    background-color: var(--primary-active-bg);
    box-shadow: inset 0 0 0 0.1rem var(--primary-color);
  }
  &:hover {
    background-color: var(--border-color);
  }
  .parameter {
    padding-left: 0;
  }
  img {
    width: 100%;
    border-radius: 0.4rem;
  }
}
</style>
