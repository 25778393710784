import { vertexShaderNoMatrix } from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision highp float;

in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uPingPongTexture;
uniform float uSize;
uniform vec3 uColor;
uniform vec2 uResolution;
uniform vec2 uPos;
uniform float uTime;
uniform float uBlur;

out vec4 fragColor;

void main() {
    // Setup
    vec2 R = uResolution;
    vec2 fragCoord = vTextureCoord * R;
    float time = uTime + 5.0 * 0.0001;
    float z = 6.0;
    
    const int N = 100;
    vec3 startColor = vec3(0.00, 0.64, 0.20);
    vec3 endColor = vec3(0.06, 0.35, 0.85);
    float startRadius = 0.001;
    float endRadius = 0.005;
    float power = 0.02;
    float duration = 4.0;

    // Transform coordinates
    vec2 v = z * (2.0 * fragCoord - R) / R.y;
    
    vec3 col = vec3(0.0);
    float sum = 0.0;
    
    // Swirl evolution
    float evo = (sin(time * 0.01 + 400.0) * 0.5 + 0.5) * 0.01 + 1.0;

    // Particle loop
    for(int i = 0; i < N; i++) {
        float d = fract(time * power + 48934.4238 * sin(float(i / int(evo)) * 692.7398));
        float a = 6.28318 * float(i) / float(N);
        
        float x = d * cos(a) * duration;
        float y = d * sin(a) * duration;
        
        float distRatio = d / duration;
        float mbRadius = mix(startRadius, endRadius, distRatio);
        mbRadius *= (1. - distance(vTextureCoord, uPos));
        
        vec2 p = v - vec2(x, y);
        float mb = mbRadius / dot(p, p);
        
        sum += mb;
        vec3 mixColor = mix(startColor, endColor, distRatio);
        col = mix(col, mixColor, mb / sum);
    }

    // Final shaping
    sum = clamp(sum, 0.0, 0.4);
    col = normalize(col) * sum;
    
    vec3 tex = vec3(1.0);
    col *= smoothstep(tex, vec3(0.0), vec3(sum));
    
    fragColor = vec4(col * 2. * uColor, 1.0);
}
`;

const params = {
    fragmentShader,
    vertexShader: vertexShaderNoMatrix,
    crossorigin: 'Anonymous',
    texturesOptions: {
        floatingPoint: 'half-float',
        premultiplyAlpha: true,
    },
    uniforms: {
        resolution: {
            name: 'uResolution',
            type: '2f',
            value: new Vec2(1080),
        },
        pos: {
            name: 'uPos',
            type: '2f',
            value: new Vec2(0.5),
        },
        size: {
            name: 'uSize',
            type: '1f',
            value: 0.05,
        },
        blur: {
            name: 'uBlur',
            type: '1f',
            value: 0.5,
        },
        color: {
            name: 'uColor',
            type: '3f',
            value: new Vec3(1),
        },
        time: {
            name: 'uTime',
            type: '1f',
            value: 0,
        },
    },
};

export const PARTICLES = {
    id: 'particles',
    label: 'Particles',
    params,
    aspectRatio: 1,
    animation: {
        active: true,
        speed: 1,
    },
    properties: {
        pos: {
            label: 'Position',
            value: new Vec2(0.5),
            min: 0,
            max: 1,
            step: 0.01,
            output: 'percent',
        },
        size: {
            label: 'Size',
            value: 0.05,
            min: 0,
            max: 0.2,
            step: 0.001,
            output: 'percent',
        },
        decay: {
            label: 'Decay',
            value: 0.75,
            min: 0,
            max: 1,
            step: 0.01,
            output: 'percent',
        },
        blur: {
            label: 'Blur',
            value: 0.5,
            min: 0,
            max: 1,
            step: 0.01,
            output: 'percent',
        },
        speed: {
            label: 'Speed',
            value: 1,
            min: 0,
            max: 2,
            step: 0.01,
            output: 'percent',
        },
        color: {
            label: 'Color',
            value: new Vec3(1),
            output: 'color',
        },
    },
}; 