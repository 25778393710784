import { computeFragColor, vertexShader, FLOATING_POINT, UNIVERSAL_UNIFORMS, universalUniformParams, interactiveProperties } from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const pixelateShader = `#version 300 es
  precision mediump float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uAmount;
  uniform float uCellRadius;
  uniform float uMixX;
  uniform float uMixY;
  uniform int uShowBg;
  uniform vec2 uPos;
  uniform vec2 uMix;
  uniform vec3 uBackground;
  ${UNIVERSAL_UNIFORMS}

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
    float aspectRatio = uResolution.x/uResolution.y;
    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    float gridSize = (uAmount + 0.01) / 12.;
    vec2 modulate = mod(vec2(uv.x * aspectRatio, uv.y) - pos * vec2(aspectRatio, 1), gridSize);
    vec2 pixelatedCoord = vec2(
      uv.x - modulate.x / aspectRatio + (0.08333 * uAmount)/2.,
      uv.y - modulate.y + (0.08333 * uAmount)/2.
    );

    vec2 cMod = modulate + 0.5 * gridSize - vec2(gridSize)*0.5;
    float dist = 1. - length(cMod - vec2(0.5 * gridSize, 0.5 * gridSize)) / length(gridSize);

    float x = mix(uv.x, pixelatedCoord.x, uMix.x);
    float y = mix(uv.y, pixelatedCoord.y, uMix.y);
    
    vec4 color = texture(uTexture, vec2(x, y));

    if(dist < (1.-uCellRadius)) {
      if(uShowBg == 1) {
        color.rgb = uBackground;
      } else {
        discard;
      }
    }

    color *= color.a;

    ${computeFragColor('color')}
  }
`;

const pixelateParams = {
  fragmentShader: pixelateShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT
  },
  uniforms: {
    amount: {
      name: "uAmount",
      type: "1f",
      value: 0,
    },
    cellRadius: {
      name: "uCellRadius",
      type: "1f",
      value: 1,
    },
    mix: {
      name: "uMix",
      type: "2f",
      value: new Vec2(1),
    },
    pos: {
      name: "uPos",
      type: "2f",
      value: new Vec2(0.5),
    },
    showBg: {
      name: "uShowBg",
      type: "1i",
      value: 1,
    },
    background: {
      name: "uBackground",
      type: "3f",
      value: new Vec3(0),
    },
    ...universalUniformParams
  }
}

export const PIXELATE = {
  id: 'pixelate',
  label: 'Pixelate',
  params: pixelateParams,
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    amount: {
      label: 'Amount',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    mix: {
      label: 'Mix',
      value: new Vec2(1),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    cellRadius: {
      label: 'Cell radius',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    showBg: {
      label: 'Background',
      value: 1,
      classic: true,
      options: {
        1: 'On',
        0: 'Off'
      }
    },
    background: {
      label: 'Color',
      value: new Vec3(0),
      output: 'color',
      hideable: {
        visible: true,
        prop: 'showBg'
      }
    },
    ...interactiveProperties
  }
}