<script>
  import InputField from './InputField.vue';
  import Icon from './Icon.vue';
  import ParamLabel from './ParamLabel.vue';

  export default {
    components: {
      InputField,
      ParamLabel,
      Icon
    },
    props: ['id', 'label', 'prop', 'modelValue', 'min', 'max', 'step', 'output', 'locked', 'quadratic', 'tooltip', 'header', 'prop'],
    emits: ['update:modelValue', 'update', 'toggle-lock', 'focus', 'blur', 'change', 'click-label'],
    computed: {
      otherDesigns() {
        return this.designs;
      },
      sliderTrack() {
        return {
          background: `linear-gradient(to right, var(--selected-radio-color) 0%, var(--selected-radio-color) ${this.valuePercent * 100 - 0.01}%, var(--accent-color) ${this.valuePercent * 100}%, var(--accent-color) 100%)`
        }
      },
      valuePercent() {
        return (this.modelValue - this.min)/(this.max - this.min)
      },
    },
    methods: {
      updateValue(val) {
        if(!(val instanceof Event)) {
          this.$emit('update:modelValue', val);
          this.$emit('update');
        }
      },
      handleTypeInput(e) {
        e = (e) ? e : window.event;
        var charCode = (e.which) ? e.which : e.keyCode;
        if(charCode === 13) {
          e.target.blur();
        }
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || e.target.value > 999) {
          e.preventDefault();
        } else {
          return true;
        }
      }
    }
  }
</script>

<template>
    <div class="parameter parameter__block flex direction-column">
      <div v-if="header" class="parameter parameter__block"><label class="parameter-label mt-2">{{header}}</label></div>
      <div class="parameter parameter__block">
        <label v-if="label" class="icon-label slider-label">
          <ParamLabel 
            :prop="prop"
            :label="label"
            :tooltip="tooltip"
            @reset-breakpoint-prop="$emit('update')"
          >{{ label }}</ParamLabel>
        </label>
        <div class="param-width flex ml-auto">
          <div class="slider-input-container">
            <input
              :value="modelValue"
              @input="updateValue(+$event.target.value)"
              @focus="$emit('focus')"
              @blur="$emit('blur')"
              @change="$emit('change')"
              type="range"
              :min="min"
              :max="max"
              :step="step"
              :style="sliderTrack"
              draggable="false"
            >
          </div>
          <InputField
            :value="modelValue"
            :output="output"
            :min="min"
            :max="max"
            @change="updateValue"
            :prop="prop"
          >
          </InputField>
        </div>
      </div>
    </div>
</template>

<style lang="scss">

.slider-info {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.slider-param-container {
  width: 100%;
  margin-top: 1rem;
}

.slider-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 8rem;
  margin-right: 0.5rem;
}

.slider-param-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 10rem;
  overflow: hidden;
}

input[type='range'] {
  appearance: none;
  width: 100%;
  outline: none;
  padding: 0;
  cursor: ew-resize;
  height: 3rem;
  border-radius: 0.4rem;
  background-color: var(--accent-color);
  margin: 0;
  user-select: none;

  // max-width: 10rem;
  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    width: 0.6rem;
    height: 3rem;
    border-radius: 0.2rem;
    opacity: 0.5;
    background: var(--font-secondary-color);
    box-shadow: 0 0.1rem 0.4rem -0.05rem rgba(#000, 0.4);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    appearance: none;
    width: 0.6rem;
    height: 3rem;
    opacity: 0.5;
    border-radius: 0.2rem;
    background: var(--font-secondary-color);
    cursor: pointer;
  }

  // Focus state
  &:focus {
    &::-webkit-slider-thumb {
      box-shadow: 0 0 0 3px var(--primary-color);
      background: var(--white);
      opacity: 1;
    }
    &::-moz-range-thumb {
      box-shadow: 0 0 0 3px var(--primary-color);
      background: var(--white);
      opacity: 1;
    }
  }
}

// Firefox Overrides
::-moz-range-track {
  background: var(--accent-color);
  border: 0;
}

</style>