<script>
import Tooltip from './Tooltip.vue'
import { getEaseFunction } from '../scripts/StateEffect';
export default {
  name: 'EasingIcon',
  components: {
    Tooltip
  },
  props: ['size', 'ease', 'tooltip'],
  mounted() {
    this.drawEasingEffect();
  },
  watch: {
    ease(newVal, oldVal) {
      // Check if ease really changed to avoid unnecessary redraws
      if (newVal !== oldVal) {
        this.drawEasingEffect();
      }
    },
  },
  computed: {
    easingFunction() {
      return getEaseFunction(this.ease);
    },
    padding() {
      return this.size/5; // Padding in pixels on each side
    },
    effectiveSize() {
      // Adjusting the size to account for padding
      return this.size - this.padding * 2; // Effective drawing area size
    },
  },
  methods: {
    drawEasingEffect() {
      const canvas = this.$refs.canvas;
      if (!canvas.getContext) {
        return; // Browser does not support canvas
      }
      const ctx = canvas.getContext('2d');
      const scale = window.devicePixelRatio; // Get the device's pixel ratio

      // Adjust canvas size for the scale, considering padding
      canvas.width = this.size * scale;
      canvas.height = this.size * scale;

      // Ensure the displayed size is controlled by CSS
      canvas.style.width = `${this.size}px`;
      canvas.style.height = `${this.size}px`;

      ctx.scale(scale, scale); // Scale the context to ensure crisp rendering

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Set the line style
      ctx.strokeStyle = '#FFFFFF90';
      ctx.lineWidth = 2 / scale; // Adjust line width based on the scale
      ctx.beginPath();

      // Adjust the drawing commands to account for padding
      for (let x = 0; x <= this.effectiveSize; x++) {
        const normalizedX = x / this.effectiveSize;
        const y = this.easingFunction(normalizedX) * this.effectiveSize;

        // Adjusting x and y to account for padding
        const paddedX = x + this.padding;
        const paddedY = y + this.padding;

        if (x === 0) {
          ctx.moveTo(paddedX, this.size - paddedY);
        } else {
          ctx.lineTo(paddedX, this.size - paddedY);
        }
      }

      ctx.stroke();
    },
  },
};
</script>

<template>
  <template v-if="tooltip">
    <Tooltip :tooltip="tooltip">
      <div class="ease-icon">
        <canvas :style="{ width: size + 'px', height: size + 'px' }" ref="canvas"></canvas>
      </div>
    </Tooltip>
  </template>
  <div v-else class="ease-icon">
    <canvas :style="{ width: size + 'px', height: size + 'px' }" ref="canvas"></canvas>
  </div>
</template>

<style>
/* Style adjustments, if any */
.ease-icon {
  background-color: #d8ccf520;
  border-radius: 0.4rem;
  display: flex;
}
</style>
