<script>
import ColorInput from './ColorInput.vue';
import SliderParam from './SliderParam.vue';
import DropdownMenu from './DropdownMenu.vue';
import ClassicToggle from './ClassicToggle.vue';
import MaskControls from './MaskControls.vue';
import DisplacementControls from './DisplacementControls.vue';
import AlignmentControls from './AlignmentControls.vue';
import SizeInput from './SizeInput.vue';
import RadioToggle from './RadioToggle.vue';
import ParamLabel from './ParamLabel.vue';
import StateEffects from "./StateEffects.vue";
import InputField from './InputField.vue';
import PanelNav from './PanelNav.vue';
import { BLEND_MODES } from '../scripts/Constants.js';
import { StudioStore } from '../stores/StudioStore.js';
import { FontsStore } from '../stores/FontsStore.js';

export default {
  components: {
    ColorInput,
    SliderParam,
    SizeInput,
    InputField,
    StateEffects,
    ParamLabel,
    DisplacementControls,
    AlignmentControls,
    DropdownMenu,
    RadioToggle,
    PanelNav,
    MaskControls,
    ClassicToggle
  },
  props: [],
  emits: ['edit-fill', 'input', 'change', 'update'],
  data() {
    return {
      blendModes: BLEND_MODES,
      fonts: [],
      fontFamilies: {},
      initialFontSize: 0,
      initialLineHeight: 0,
      initialWidth: 0,
      state: StudioStore.state,
      textAlignOptions: [
        {
          value: 'left',
          label: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 256 256"><path fill="none" d="M0 0h256v256H0z"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M40 68h176"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M40 108h128"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M40 148h176"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M40 188h128"/></svg>'
        },
        {
          value: 'center',
          label: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 256 256"><path fill="none" d="M0 0h256v256H0z"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M40 68h176"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M64 108h128"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M40 148h176"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M64 188h128"/></svg>'
        },
        {
          value: 'right',
          label: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 256 256"><path fill="none" d="M0 0h256v256H0z"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M40 68h176"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M88 108h128"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M40 148h176"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M88 188h128"/></svg>'
        }
      ],
      anchorOptions: {
        center: 'Center',
        topLeft: 'Top Left',
        topCenter: 'Top Center',
        topRight: 'Top Right',
        rightCenter: 'Right Center',
        bottomRight: 'Bottom Right',
        bottomCenter: 'Bottom Center',
        bottomLeft: 'Bottom Left',
        leftCenter: 'Left Center',
      },
      panelView: 'design',
      panelOptions: {
        design: 'Design',
        interactivity: 'Events'
      },
    }
  },
  mounted() {
    this.handleFontSelection();
    this.initialFontSize = this.textBox.fontSize;
    this.initialLineHeight = this.textBox.lineHeight;
    if(!FontsStore.state.googleFonts.length) {
      FontsStore.loadGoogleFonts();
    }
  },
  created() {
    if(this.stateEffect) {
      this.panelView = 'interactivity';
    }
  },
  computed: {
    textBox() {
      return StudioStore.getSelectedItem() || StudioStore.state.text;
    },
    fontStyles() {return this.getFontStyles()},
    fontData() {return {...FontsStore.state.curatedFonts, ...FontsStore.state.googleFonts}},
    stateEffect() {
      return StudioStore.state.openStateEffect;
    }
  },
  watch: {
    stateEffect() {
      if(this.stateEffect) {
        this.panelView = 'interactivity';
      }
    }
  },
  methods: {
    getFillStyle(fill) {
      return getFillStyle(fill);
    },
    handleChange(val, prop) {
      if(prop === 'W') {
        this.textBox.width = val;
      }
      if(prop === 'H') {
        this.textBox.height = val;
      }
      if(prop === 'X') {
        this.textBox.translateX = val;
      }
      if(prop === 'Y') {
        this.textBox.translateY = val;
      }
      this.$emit('change');
    },
    handleFontSizeChange() {
      this.$emit('input');
    },
    handleBlendModeChange() {
      this.$emit('change');
    },
    handleBlendModePreview() {
      StudioStore.renderFrame();
    },
    getFontStyles() {
      if(this.fontData[this.textBox.fontFamily] && this.fontData[this.textBox.fontFamily].variants) {
        return this.fontData[this.textBox.fontFamily].variants.reduce((accumulator, value) => {
          return {...accumulator, [value]: value};
        }, {});
      } else {
        return {regular: 'regular'}
      }
    },
    handleColorChange(e) {
      if(!(e instanceof Event)) {
        this.textBox.fill = e;
        this.$emit('change');
      }
    },
    handleFontSelection() {
      const data = this.fontData[this.textBox.fontFamily];
      if(data) {
        // this.fontStyles = data.variants.reduce((accumulator, value) => {
        //   return {...accumulator, [value]: value};
        // }, {});

        if(!this.fontStyles[this.textBox.fontStyle]) {
          this.textBox.fontStyle = 'regular';
        }

        this.textBox.fontCSS = {
          family: data.family,
          src: data.files[this.textBox.fontStyle]
        }

        const fontFace = new FontFace(this.textBox.fontFamily, `url(${data.files[this.textBox.fontStyle]})`, {});
        document.fonts.add(fontFace);
        fontFace.load().then(() => {
          requestAnimationFrame(() => {
            if(this.textBox && this.textBox.render) {
              this.textBox.render();
              StudioStore.renderNFrames(2);
            }
          });
        });
      }
    },
    handleAlphaChange(e) {
      if(!(e instanceof Event)) {
        this.textBox.maskBackgroundAlpha = e;
      }
    },
    handleWidthModeChange(mode) {
      this.textBox.setDimensionMode('width', mode);
      this.textBox.setDimensionMode('height', mode);
    }
  }
}
</script>

<template>
  <div class="parameters effect-properties control-section-wrapper">
    <div class="parameter parameter__block">
      <label class="parameter-label">Text box</label>
    </div>

    <PanelNav
      v-model="panelView"
      :options="panelOptions"
    />

    <template v-if="panelView === 'interactivity'">
      <StateEffects class="w-100" :item="textBox" />
    </template>
    <template v-else-if="panelView === 'design'">

      <AlignmentControls :item="textBox" @change="$emit('change')" />
  
      <MaskControls
      :item="textBox"
      @change="$emit('change')"
      @edit-fill="$emit('edit-fill', {item: textBox, prop: 'maskBackground'})"
      />
      <div class="parameter parameter__block">
        <ParamLabel 
            prop="textContent"
            label="Text"
        >Text</ParamLabel>
        <textarea class="input-field input-field__textarea param-width" 
          v-model="textBox.textContent"
          @input="$emit('input')"
        ></textarea>
      </div>
      <div class="parameter parameter__block">
        <ParamLabel 
            :props="['left', 'top']"
            label="Position"
            @reset-breakpoint-prop="$emit('update')"
        >Position</ParamLabel>
        <div class="coords-input-group">
          <InputField
            label="X"
            v-model="textBox.left"
            output="percent"
            :slider="true"
            @change="$emit('input')"
          />
          <InputField
            label="Y"
            v-model="textBox.top"
            output="percent"
            :slider="true"
            @change="$emit('input')"
          />
        </div>
      </div>
      <div class="parameter parameter__block">
        <ParamLabel prop="anchorPoint" label="Anchor">Anchor</ParamLabel>
        <DropdownMenu
          :modelValue="textBox.anchorPoint"
          :options="anchorOptions"
          prop="anchorPoint"
          @update="$emit('update')"
          @change="
            textBox.setAnchorPoint($event);
            $emit('input');
          "
        ></DropdownMenu>
      </div>
      <SizeInput
        label="Width"
        v-model="textBox.width"
        :mode="textBox.widthMode"
        :auto="false"
        dimension="width"
        @input="$emit('input')"
        @update="$emit('update')"
        @change-mode="handleWidthModeChange($event)"
      />
      <SliderParam label="Rotation"
        v-model="textBox.rotation"
        :min="0"
        :max="1"
        :step="0.0027"
        output="degrees"
        prop="rotation"
        @update="$emit('input')"
      ></SliderParam>
      <SliderParam label="Opacity"
          v-model="textBox.opacity"
          :min="0"
          :max="1"
          :step="0.01"
          output="percent"
          prop="opacity"
          @update="$emit('input')"
      ></SliderParam>
      <div class="parameter parameter__block">
        <ParamLabel 
            prop="blendMode"
            label="Blend mode"
            @reset-breakpoint-prop="$emit('update')"
        >Blend mode</ParamLabel>
        <DropdownMenu
          v-model="textBox.blendMode"
          :options="blendModes"
          :rolloverPreview="true"
          @input="handleBlendModePreview"
          @update:modelValue="handleBlendModePreview"
          @change="handleBlendModeChange"
        ></DropdownMenu>
      </div>
      <DisplacementControls
        :item="textBox"
        @input="$emit('input')"
        @change="$emit('change')"
      ></DisplacementControls>
      <div class="parameter parameter__block"><label class="parameter-label mt-2">Text</label></div>
      <div class="parameter parameter__block">
        <ParamLabel 
            prop="font"
            label="Font"
            @reset-breakpoint-prop="$emit('update')"
        >Font</ParamLabel>
        <DropdownMenu
          v-model="textBox.fontFamily"
          :options="[]"
          @click="state.browsingFonts = true"
        ></DropdownMenu>
      </div>
      <div class="parameter parameter__block">
        <ParamLabel 
            prop="fontStyle"
            label="Font style"
            @reset-breakpoint-prop="$emit('update')"
        >Font style</ParamLabel>
        <DropdownMenu
          v-model="textBox.fontStyle"
          :options="fontStyles"
          :rolloverPreview="true"
          @update:modelValue="handleFontSelection"
        ></DropdownMenu>
      </div>
      <SizeInput
        label="Font size"
        v-model="textBox.fontSize"
        :mode="textBox.fontSizeMode"
        :auto="false"
        :disableAuto="true"
        dimension="fontSize"
        @input="$emit('input')"
        @update="$emit('update')"
        @change-mode="textBox.setFontMode($event)"
      />
      <SliderParam 
        label="Line height"
        v-model="textBox.lineHeight"
        :min="0"
        :max="textBox.fontSizeMode === 'relative' ? 3 : 144"
        :step="textBox.fontSizeMode === 'relative' ? 0.01 : 1"
        :output="textBox.fontSizeMode === 'relative' ? 'percent' : 'px'"
        prop="lineHeight"
        @update="$emit('input')"
      ></SliderParam>
      <SliderParam 
        label="Letterspacing"
        v-model="textBox.letterSpacing"
        :min="textBox.fontSizeMode === 'relative' ? -0.5 : -16"
        :max="textBox.fontSizeMode === 'relative' ? 0.5 : 16"
        :step="textBox.fontSizeMode === 'relative' ? 0.005 : 1"
        :output="textBox.fontSizeMode === 'relative' ? 'percent' : 'px'"
        prop="letterSpacing"
        @update="$emit('input')"
      ></SliderParam>
      <div class="parameter parameter__block">
        <RadioToggle
          label="Alignment"
          icon="true"
          :options="textAlignOptions"
          v-model="textBox.textAlign"
          @change="$emit('change')"
        />
      </div>
      <div class="parameter parameter__block">
        <ParamLabel 
            prop="fill"
            label="Fill"
            @reset-breakpoint-prop="$emit('update')"
        >Fill</ParamLabel>
        <ColorInput
          :fill="textBox.fill"
          :clearable="true"
          :gradientType="textBox.gradientType"
          @change="handleColorChange"
          @click-swatch="$emit('edit-fill', {item: textBox, prop: 'fill'})"
        ></ColorInput>
      </div>
      <SliderParam 
        label="Mouse tracking"
        header="Interactivity"
        v-model.number="textBox.trackMouse"
        :min="0"
        :max="1"
        :step="0.01"
        prop="trackMouse"
        @update="$emit('input')"
        output="percent"
      ></SliderParam>
      <SliderParam 
        label="Momentum"
        v-model.number="textBox.mouseMomentum"
        tooltip="The amount of drag or delay of the track mouse effect"
        :min="0"
        :max="1"
        :step="0.01"
        output="percent"
        prop="mouseMomentum"
        @update="$emit('input')"
      ></SliderParam>
      <SliderParam 
        label="3D axis tilt"
        v-model.number="textBox.axisTilt"
        :min="0"
        :max="1"
        :step="0.01"
        prop="axisTilt"
        @update="$emit('input')"
        output="percent"
      ></SliderParam>
    </template>


  </div>
</template>
