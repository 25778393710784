import { Vec2 } from 'curtainsjs';
import {
  vertexShader,
  UNIVERSAL_UNIFORMS,
  BLEND,
  FLOATING_POINT,
  universalUniformParams,
  interactiveProperties,
  computeFragColor,
} from '../ShaderHelpers.js';
import { BLEND_MODES } from '../Constants.js';

const fragmentShader = `#version 300 es
precision mediump float;

in vec2 vTextureCoord;
uniform sampler2D uTexture;
uniform sampler2D uVideoTexture;
uniform int uBlendMode;
uniform float uScale;
uniform float uOpacity;
uniform vec2 uPos;
uniform float uAngle;
uniform int uFitToCanvas;
uniform float uDisplaceStrength;
${UNIVERSAL_UNIFORMS}
${BLEND}

out vec4 fragColor;

const float PI = 3.14159265359;
mat2 rot(float a) {
  return mat2(cos(a),-sin(a),sin(a),cos(a));
}

void main() {
    vec2 uv = vTextureCoord;
    vec4 bg = texture(uTexture, uv);

    ivec2 texSize = textureSize(uVideoTexture, 0);
    float texWidth = float(texSize.x);
    float texHeight = float(texSize.y);
    float videoAspect = texWidth / texHeight;

    float screenAspect = (uResolution.x / uResolution.y);
    vec2 centeredUV = uv;

    if (uFitToCanvas == 1) {
      vec2 centered = uv - 0.5;
      if (screenAspect < videoAspect) {
        centeredUV = vec2( (uv.x - 0.5) * (screenAspect / videoAspect), uv.y - 0.5) + 0.5;
      } else {
        centeredUV = vec2( uv.x - 0.5, (uv.y - 0.5) * (videoAspect / screenAspect) ) + 0.5;
      }
      centeredUV += (bg.rg * 2.0 - 1.0) * uDisplaceStrength;
    } else {
      vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
      centeredUV = centeredUV - pos;
      centeredUV.x *= screenAspect;
      centeredUV = (centeredUV) / uScale;
      centeredUV = centeredUV * rot(uAngle * 2.0 * PI);
      centeredUV.x /= videoAspect;
      centeredUV += 0.5;
      centeredUV += (bg.rg * 2.0 - 1.0) * uDisplaceStrength;
      
      if(centeredUV.x < 0.0 || centeredUV.x > 1.0 || centeredUV.y < 0.0 || centeredUV.y > 1.0) {
        fragColor = bg;
        return;
      }
    }
    
    

    vec4 color = texture(uVideoTexture, centeredUV);

    if(uBlendMode > 0) {
      color.rgb = blend(uBlendMode, bg.rgb, color.rgb);
    }

    color.rgb = mix(bg.rgb, color.rgb, uOpacity) * color.a;

    ${computeFragColor('color')}
}`;

export const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'anonymous',
  depth: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },

  uniforms: {
    fitToCanvas: {
      name: 'uFitToCanvas',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 1,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    opacity: {
      name: 'uOpacity',
      type: '1f',
      value: 1,
    },
    displace: {
      name: 'uDisplaceStrength',
      type: '1f',
      value: 0,
    },
    blendMode: {
      name: 'uBlendMode',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const VIDEO = {
  id: 'video',
  label: 'Video',
  params: params,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    fitToCanvas: {
      label: 'Fit to canvas',
      value: 0,
      options: [
        { value: 0, label: 'Off' },
        { value: 1, label: 'On' },
      ],
      classic: true,
    },
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    angle: {
      label: 'Rotation',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    src: {
      label: 'Source URL',
      value: 'https://assets.unicorn.studio/media/lambo.mp4',
      output: 'string',
      tooltip: 'Source link to the hosted video file',
      type: 'string',
      responsiveDisabled: true,
      eventDisabled: true,
    },
    opacity: {
      label: 'Opacity',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    displace: {
      label: 'Displace',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    blendMode: {
      label: 'Blend mode',
      value: 'NORMAL',
      options: BLEND_MODES,
      responsiveDisabled: true,
      eventDisabled: true,
    },
    ...interactiveProperties,
  },
};
