import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  CLIP_EFFECT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';

const ditherShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uBlueNoise;

uniform float uAmount;
uniform float uDither;
uniform int uType;
uniform float uTime;
${UNIVERSAL_UNIFORMS}

const int MAX_LEVEL = 4;
const float PI2 = 6.28318530718;

float getBayerFromCoordLevelScaled(vec2 pixelpos, float scale) {
    float finalBayer = 0.0;
    float finalDivisor = 0.0;
    float layerMult = 1.0;

    for(float bayerLevel = float(MAX_LEVEL); bayerLevel >= 1.0; bayerLevel--) {
        float bayerSize = exp2(bayerLevel) * 0.5 / scale;
        vec2 bayercoord = mod(floor(pixelpos.xy / bayerSize), 2.0);
        layerMult *= 4.0;

        float byxx2 = bayercoord.x * 2.0;

        finalBayer += mix(byxx2, 3.0 - byxx2, bayercoord.y) / 3.0 * layerMult;
        finalDivisor += layerMult;
    }

    return finalBayer / finalDivisor;
}

float random(vec2 seed) {
  return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

float getBlueNoise(vec2 st, float delta, float size) {
    ivec2 texSize = textureSize(uBlueNoise, 0);
    vec4 blueNoise = texelFetch(uBlueNoise, ivec2(fract(st * (uResolution/size)/vec2(texSize) * vec2(texSize.x/texSize.y, 1.0)) * vec2(texSize)) % texSize, 0);
    return mod((blueNoise.r * PI2) + (delta * (1.0 / PI2)), PI2) / PI2;
}

float getBayerNoise(vec2 st, float delta, float scale) {
    return getBayerFromCoordLevelScaled(st * uResolution + delta, scale);
}

float getRandNoise(vec2 st, vec2 offset) {
    return fract(sin(dot(st + offset, vec2(12.9898, 78.233))) * 43758.5453) + 0.001;
}

vec3 dither(vec3 color, vec2 st) {
  float delta = floor(uTime);
  vec2 offset = vec2(random(vec2(123,16) + delta), random(vec2(56,96) + delta));
  float noise = 0.0;
  switch(uType) {
      case 0: noise = getBlueNoise(st, delta, 2.); break;
      case 1: noise = getBayerNoise(st, delta, 0.5); break;
      case 2: noise = getRandNoise(st, offset); break;
      case 3: noise = getBayerNoise(st, delta, 1.); break;
      case 4: noise = getBayerNoise(st, delta, 0.25); break;
      case 5: noise = getBlueNoise(st, delta, 4.); break;
      case 6: noise = getBlueNoise(st, delta, 1.); break;
      default: noise = 0.0;
  }
  float dither = max(0.0001, uDither);
  color += (noise - 0.505) * dither;
  return round(color * (1.0 / dither)) * dither;
}

out vec4 fragColor;

void main() {
    vec2 uv = vTextureCoord;
    float delta = floor(uTime);
    vec4 color = texture(uTexture, uv);

    if(color.a == 0.) {
      fragColor = vec4(0);
      return;
    }

    color.rgb = mix(color.rgb, dither(color.rgb, uv), uAmount);

    ${computeFragColor('color')}
}

`;

const ditherParams = {
  fragmentShader: ditherShader,
  vertexShader,
  crossOrigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    dithering: {
      name: 'uDither',
      type: '1f',
      value: 0.5,
    },
    noiseType: {
      name: 'uType',
      type: '1i',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const DITHER = {
  id: 'dither',
  label: 'Dither',
  params: ditherParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 0.5,
  },
  texture: {
    src: 'https://assets.unicorn.studio/media/blue_noise_med.png',
    sampler: 'uBlueNoise',
  },
  properties: {
    noiseType: {
      label: 'Type',
      value: 0,
      options: {
        0: 'Blue noise',
        5: 'Blue noise 2x',
        6: 'Blue noise 0.5x',
        4: 'Bayer 4x4',
        1: 'Bayer 8x8',
        3: 'Bayer 16x16',
        2: 'Random',
      },
      responsiveDisabled: true,
    },
    dithering: {
      label: 'Threshold',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Mix',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
