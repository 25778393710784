<script>
import Icon from "./Icon.vue";
import EasingIcon from "./EasingIcon.vue";
import Button from "./Button.vue";
import ActionMenu from "./ActionMenu.vue";
import DisplayValue from "./DisplayValue.vue";
import { StudioStore } from '../stores/StudioStore'
import { rgbToHex } from '../scripts/ColorHelpers'

export default {
  components: { Icon, EasingIcon, Button, ActionMenu, DisplayValue },
  props: ['stateEffect', 'item'],
  emits: ['delete-state-effect', 'handle-click', 'duplicate-state-effect'],
  methods: {
    deleteStateEffect() {
      this.$emit('delete-state-effect', this.stateEffect.id);
    },
    duplicateStateEffect() {
      this.$emit('duplicate-state-effect', this.stateEffect);
    },
    handleClick(e) {
      this.$emit('handle-click', this.stateEffect, e);
    },
    handleStateEffectAction(action) {
      if(action === 'delete') {
        this.deleteStateEffect();
      } else if(action === 'duplicate') {
        this.duplicateStateEffect();
      }
    },
    getHex(value) {
      return rgbToHex(
              Math.floor(value.x * 255),
              Math.floor(value.y * 255),
              Math.floor(value.z * 255)
            )
    }
  },
  computed: {
    params() {
      return this.item.getParams();
    },
    label() {
      return this.params.properties[this.stateEffect.prop]?.specificLabel || this.params.properties[this.stateEffect.prop]?.label || "Undefined"
    },
    selectedEffect() {
      return StudioStore.state.openStateEffect;
    },
  }
};
</script>

<template>
  <div class="flex align-center no-select">
    <a href="#" @click="handleClick" :class="{'state-effect-item__selected': stateEffect.id === selectedEffect?.id}" class="state-effect-item flex align-center w-100">
      <div class="flex align-center justify-between w-100">
        <div v-if="this.stateEffect.prop && label" class="capitalize ml-1 font-secondary-color">{{label}}</div>
        <!-- <div class="flex align-center">
          <EasingIcon v-if="stateEffect.type === 'appear' || stateEffect.type === 'hover'" size="20" class="" :ease="stateEffect.transition.ease" :tooltip="'Duration: ' + stateEffect.transition.duration + ' / Delay: ' + stateEffect.transition.delay" />
        </div> -->
      </div>
    </a>
    <ActionMenu class="font-secondary-color" size="15" icon="ellipsis" @select-option="val => handleStateEffectAction(val)" :options="[{label: 'Duplicate', value: 'duplicate'}, {label: 'Delete', value: 'delete'}]" />

  </div>
</template>

<style scoped lang="scss">

  .state-effect-item {
    border-radius: 0.4rem;
    padding: 0.6rem 0.2rem 0.6rem 0;
    text-decoration: none;
    margin-left: -0.5rem;

    &:hover {
      background-color: var(--accent-color);
    }
    
    &:last-child {
      margin-bottom: 1rem;
    }
    
    &.state-effect-item__selected {
      background-color: var(--primary-color);
      color: white;

      .state-effect-value, .capitalize.font-secondary-color {
        color: white;
      }
    }
  }

  .state-effect-icon {
    padding: 0.4rem;
    border-radius: 0.4rem;
    margin-right: 0.2rem;
  }

  .state-effect-item + .state-effect-item {
    margin-top: 1rem;
  }

</style>