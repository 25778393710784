<script>
import Icon from "./Icon.vue";
import ActionMenu from "./ActionMenu.vue";
import { StudioStore } from '../stores/StudioStore'

export default {
  components: { Icon, ActionMenu },
  props: ['label', 'tooltip', 'prop', 'props'],
  emits: ['reset-breakpoint-prop'],
  methods: {
    handleClick(e) {
      if(StudioStore.state.hotkeys.isPressed('option')) {
        if(this.item && this.prop && this.item.resetProp) {
          this.item.resetProp(this.prop);
        }
      } else {
        this.$emit('click-label', e);
      }
    },
    resetBreakpointProp() {
      if(this.props) {
        this.props.forEach(prop => {
          this.item.resetBreakpointProp(prop);
        });
      } else {
        this.item.resetBreakpointProp(this.prop);
      }
      this.$emit('reset-breakpoint-prop');
    },
  },
  watch: {
    prop() {
      this.isBreakpoint = this.item?.isBreakpoint(this.prop) && this.nonDesktop;
    }
  },
  computed: {
    item() {
      return StudioStore.getSelectedItem();
    },
    nonDesktop() {
      return this.item?.getCurrentBreakpoint()?.name !== 'Desktop';
    },
    hasState() {
      return this.item?.states.appear.some(effect => effect.prop === this.prop);
    },
    isBreakpoint() {
      return this.props ? this.props.some(p => this.item?.isBreakpoint(p) && this.nonDesktop) : this.item?.isBreakpoint(this.prop) && this.nonDesktop
    },
  }
};
</script>

<template>
  <span @click="handleClick" :title="label" class="icon-label no-select slider-label relative" :class="{'is_breakpoint': isBreakpoint}">
    <span class="param-label"><slot></slot></span>
    <Icon v-if="tooltip" icon="info" class="ml-1" size="13" :tooltip="tooltip" :class="{'font-tertiary-color': !isBreakpoint}"/>
    <ActionMenu v-if="isBreakpoint" @select-option="resetBreakpointProp" icon="ellipsis" :options="[{label: 'Reset', value: 'reset'}]" />
  </span>
</template>

<style scoped lang="scss">

.icon-label.is_breakpoint {
  background-color: var(--primary-color-50a) !important;
  color: var(--primary-color-light);
  padding: 0 0 0 0.4rem;
  border-radius: 0.4rem;

  .param-label {
    max-width: 5.5rem;
  }
}

.param-label {
  white-space: nowrap;
  max-width: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>