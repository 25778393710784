<script>
import { rgbToHex } from '../scripts/ColorHelpers'

export default {
  props: ['value', 'params', 'mode'],
  methods: {
    getValueType() {
      console.log(this.params)
      if(this.params.output === 'percent' && this?.mode !== 'fixed') {
        return 'percent';
      } else if(this.params.output === 'pixels' || this?.mode === 'fixed') {
        return 'pixels';
      } else if (this.params.output === 'color') {
        return 'pixels';
      } else if(this.params.value?.type === 'Vec2') {
        return 'vec2';
      } else if(this.params.value?.type === 'Vec3') {
        return 'vec3';
      }
      return 'string';
    }
  },
  computed: {
    formattedValue() {
      if (this.value === null || this.value === undefined) return '-';
      console.log(this.getValueType())
      switch (this.getValueType()) {
        case 'pixels':
          return `${Math.round(this.value)}px`;
        
        case 'percent':
          return `${(this.value * 100).toFixed(this.precision)}%`;
        
        case 'vec2':
          if (!this.value.x && !this.value.y) return '0, 0';
          return `${(this.value.x || 0).toFixed(this.precision)}, ${(this.value.y || 0).toFixed(this.precision)}`;
        
        case 'vec3':
          if (!this.value.x && !this.value.y && !this.value.z) return '0, 0, 0';
          return `${(this.value.x || 0).toFixed(this.precision)}, ${(this.value.y || 0).toFixed(this.precision)}, ${(this.value.z || 0).toFixed(this.precision)}`;
        
        case 'color':
          // Handle hex code
          if (typeof this.value === 'string') {
            return this.value;
          }
          // Handle Vec3 color
          if (this.value.x !== undefined) {
            return rgbToHex(
              Math.floor(this.value.x * 255),
              Math.floor(this.value.y * 255),
              Math.floor(this.value.z * 255)
            );
          }
          return this.value;

        default:
          return this.value.toString();
      }
    }
  }
}
</script>

<template>
  <span class="value-display" :class="format">
    {{ formattedValue }}
  </span>
</template>

<style scoped lang="scss">
.value-display {
  font-family: var(--mono-font);
  font-size: 0.9em;
  
  &.color {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    
    &::before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 2px;
      background-color: v-bind(formattedValue);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}
</style>
