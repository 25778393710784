<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:modelValue']
}
</script>

<template>
  <div class="panel-nav mt-2">
    <div 
      v-for="(label, value) in options" 
      :key="value"
      class="panel-nav-item"
      :class="{ active: modelValue === value }"
      @click="$emit('update:modelValue', value)"
    >
      {{ label }}
    </div>
  </div>
</template>

<style scoped>
.panel-nav {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
  //border-bottom: 1px solid var(--accent-color);
}

.panel-nav-item {
  padding: 0.5rem 0;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.panel-nav-item:hover {
  opacity: 1;
}

.panel-nav-item.active {
  opacity: 1;
  border-bottom: 1px solid var(--font-tertiary-color);
  margin-bottom: -1px;
}
</style> 